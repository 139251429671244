import React, { useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import Mpink from "../../assets/img/Mpink.png";
import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const PaymentResponse = () => {
  const location = useLocation();
  const response = {
    status: location?.search == "?success=false" ? "Failed" : "Success",
    content:
      location?.search == "?success=false"
        ? "Subscription Failed"
        : "You have Subscribed Successfully",
  };
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (location.search === "?success=true" && !user.emailVerified) {
          user.sendEmailVerification()
            .then(() => {
              toast.success("Email Verification has been sent");
            })
            .catch((error) => {
              toast.error("Error sending verification email:", error);
            });
        }
      }
    });

  }, [response?.status]);
    return (
    <Col lg="5" md="7" className="login">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-4">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <img src={Mpink} alt="logo" className="mb-3 w-25" />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="title">{response?.status}</h3>
              <p className="mb-1">{response?.content}</p>
              <Link to="/auth/login">Login Now</Link>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PaymentResponse;
