import { combineReducers } from "redux";
import authReducer from "./authReducer";
import catalogReducer from "./catalogReducer";
import settingReducer from "./settingReducer";
import MenuManage from "./MenuManagementReducer";
import restaurantReducer from "./restaurantReducer";
import subAdminReducer from "./subAdminReducer";
import staffReducer from "./staffReducer";
import requestReducer from "./RequestReducer";
import statsReducer from "./statsReducer";
import checkoutQuestionReducer from "./checkoutQuestionReducer";
import customerReducer from "./customerReducer";
import orderReducer from "./orderReducer";
import faqsReducer from "./faqsReducer";
import tutorialsReducer from "./tutorialsReducer";
import contactReducer from "./contactReducer";
import subscriptionReducer from "./subscriptionReducer";
import driverReducer from "./driverReducer";

const appReducer = combineReducers({
  auth: authReducer,
  catalog: catalogReducer,
  setting: settingReducer,
  menu: MenuManage,
  restaurant: restaurantReducer,
  admin: subAdminReducer,
  staff: staffReducer,
  requests: requestReducer,
  stats: statsReducer,
  driver:driverReducer,
  checkoutQuestion: checkoutQuestionReducer,
  customer: customerReducer,
  orders: orderReducer,
  faqs: faqsReducer,
  tutorials: tutorialsReducer,
  contacts: contactReducer,
  subscription: subscriptionReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined; // Reset state to its initial state
  }

  return appReducer(state, action);
};
export default rootReducer;
