/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import AllOrderCardModal from "components/Modals/AllOrderCardModal";
import TotalSalesModal from "components/Modals/TotalSalesModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  CardBody,
  Spinner,
  Input,
} from "reactstrap";

import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import TotalCustomerChart from "components/Charts/TotalCustomerChart";
import LineChart from "components/Charts/LineChart";
import HeaderCards from "./HeaderCards";
import TopMerchants from "components/Charts/TopMerchants";
import { getRestaurantStats } from "store/actions/statsActions";
import { getRestaurantStatsById } from "store/actions/statsActions";
import { getMostViewedById } from "store/actions/statsActions";
import { getMostViewedProductsById } from "store/actions/statsActions";
import { getLeastViewedProductsById } from "store/actions/statsActions";
import { getBestSellersById } from "store/actions/statsActions";
import { getWorstSellersById } from "store/actions/statsActions";
import { getResturantRevenue } from "store/actions/statsActions";
import { getOrderAnalysisById } from "store/actions/statsActions";
import { getRestaurantVisitsById } from "store/actions/statsActions";
import { getFilteredVisits } from "store/actions/statsActions";
import RestaurantCurrency from "views/RestaurantCurrency";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [modals, setModals] = useState(false);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const toggle = () => setModal(!modal);
  const toggles = () => setModals(!modals);
  const { user } = useSelector((state) => state.auth);
  const {
    restaurantStats,
    topMerchants,
    topProducts,
    orderAnalysis,
    loading,
    currentTopViewedProducts,
    currentLeastViewedProducts,
    currentRestaurantBestSellers,
    currentRestaurantWorstSellers,
    restaurantRevenue,
    revenueLoader,
    restaurantOrderAnalysis,
    restaurantAnalysisLoader,
    restaurantVisits,
    visitsLoader,
    dailyVists,
  } = useSelector((state) => state.stats);
  const { restaurantCurrency } = useSelector((state) => state.requests);

  const totalRevenue =
    (restaurantRevenue?.stripe? restaurantRevenue?.stripe:0) +
    (restaurantRevenue?.cash? restaurantRevenue?.cash:0)+
    (restaurantRevenue?.paypal? restaurantRevenue?.paypal:0);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
  const ref = useRef(null);

  const [bestSellers, setBestSellers] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        currentRestaurantBestSellers?.length > 0
          ? currentRestaurantBestSellers?.map((item) => {
              return item?.name?.split(" ")[0];
            })
          : [],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",
        barHeight: "70%",
        distributed: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 501,
              to: 2000,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 2001,
              to: 3000,
              color: "#5E72E4", // Blue shade
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " product";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [],
      },
    },
  });
  const bestSellersSeries = [
    {
      name: "Orders",
      data:
        currentRestaurantBestSellers?.length > 0
          ? currentRestaurantBestSellers?.map((val) => {
              return val?.orders;
            })
          : [],
    },
  ];

  const [worstSeller, setWorstSellers] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        currentRestaurantWorstSellers?.length > 0
          ? currentRestaurantWorstSellers?.map((item) => {
              return item?.name?.split(" ")[0];
            })
          : [],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",
        barHeight: "70%",
        distributed: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 501,
              to: 2000,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 2001,
              to: 3000,
              color: "#5E72E4", // Blue shade
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " product";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [],
      },
    },
  });
  const worstSellerSeries = [
    {
      name: "Orders",
      data:
        currentRestaurantWorstSellers?.length > 0
          ? currentRestaurantWorstSellers?.map((val) => {
              return val?.orders;
            })
          : [],
    },
  ];

  const mostViewedProducts = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: currentTopViewedProducts.map((product) => {
        // const matchingProduct = topProducts.find(
        //   (item) => item.id === product.id
        // );
        return product?.name?.split(" ")[0];
      }),
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",
        barHeight: "70%",
        distributed: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 501,
              to: 2000,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 2001,
              to: 3000,
              color: "#5E72E4", // Blue shade
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " ORDERS";
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: [],
      },
    },
    toolbar: {
      show: false,
    },
  };
  const mostViewedSeries = [
    {
      name: "Views",
      data:
        currentTopViewedProducts?.length > 0
          ? currentTopViewedProducts?.map((product) => {
              return product?.views;
            })
          : [],
    },
  ];

  const leastViewedProducts = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: currentLeastViewedProducts.map((product) => {
        // const matchingProduct = topProducts.find(
        //   (item) => item.id === product.id
        // );
        return product?.name?.split(" ")[0];
      }),
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",
        barHeight: "70%",
        distributed: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 501,
              to: 2000,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 2001,
              to: 3000,
              color: "#5E72E4", // Blue shade
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " ORDERS";
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: [],
      },
    },
    toolbar: {
      show: false,
    },
  };
  const leastViewedSeries = [
    {
      name: "Views",
      data:
        currentLeastViewedProducts.length > 0
          ? currentLeastViewedProducts?.map((product) => {
              return product?.views;
            })
          : [],
    },
  ];

  const TopProducts = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        topProducts?.length > 0
          ? topProducts?.map((val) => {
              return val?.name?.split(" ")[0];
            })
          : [],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },

    plotOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },

      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",
        barHeight: "70%",
        distributed: false,
        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 501,
              to: 2000,
              color: "#5E72E4", // Blue shade
            },
            {
              from: 2001,
              to: 3000,
              color: "#5E72E4", // Blue shade
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " ORDERS";
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: [],
      },
    },
    toolbar: {
      show: false,
    },
  };

  const ProductsSeries = [
    {
      name: "Orders",
      data:
        topProducts?.length > 0
          ? topProducts?.map((val) => {
              return val?.orders;
            })
          : [],
    },
  ];

  //   pie chart details
  const chartOptions = {
    chart: {
      type: "pie",
    },
    legend: {
      fontFamily: "Poppins, sans-serif",
    },
    labels: ["Lost Customers", "New Customers"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };
  const chartSeries = restaurantStats?.totalCustomers
    ? Object.keys(restaurantStats.totalCustomers)
        .map((key) => {
          if (key === "new" || key === "lost") {
            return restaurantStats.totalCustomers[key];
          } else {
            return null;
          }
        })
        .filter((num) => num !== null)
    : [];


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const topMerchantOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        topMerchants?.length > 0
          ? topMerchants?.map((val) => {
              return val?.name?.split(" ")[0];
            })
          : [],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },

    plotOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },

      bar: {
        horizontal: false,
        endingShape: "flat",
        columnWidth: "17%",

        colors: {
          ranges: [
            {
              from: 0,
              to: 500,
              color: "#F765A3",
            },
          ],
        },
        dataLabels: {
          position: "top",
          offsetY: -10,
          style: {
            fontSize: "12px",
            colors: [],
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + " ORDERS";
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: [],
      },
    },
    toolbar: {
      show: false,
    },
  };

  const topMerchantSeries = [
    {
      name: "Orders",
      data:
        topMerchants?.length > 0
          ? topMerchants?.map((val) => {
              return val?.orders;
            })
          : [],
    },
  ];

  const OrderAnalysisOptions = {
    chart: {
      id: "basic-line",
      toolbar: {
        show: false,
      },
      style: {
        fontFamily: "poppins",
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },
    curve: "smooth",
  };

  const OrderAnalysisSeries = [
    {
      name: "Active",
      data:
        orderAnalysis?.length > 0
          ? orderAnalysis?.map((val) => {
              return val?.active;
            })
          : [],
    },

    {
      name: "Completed",
      data:
        orderAnalysis?.length > 0
          ? orderAnalysis?.map((val) => {
              return val?.completed;
            })
          : [],
    },
    {
      name: "Pending",
      data:
        orderAnalysis?.length > 0
          ? orderAnalysis?.map((val) => {
              return val?.pending;
            })
          : [],
    },
  ];
  const restaurantAnalysisSeries = [
    {
      name: "Active",
      data: Object.values(restaurantOrderAnalysis).map((obj) => obj.active),
    },
    {
      name: "Completed",
      data: Object.values(restaurantOrderAnalysis).map((obj) => obj.completed),
    },
    {
      name: "Pending",
      data: Object.values(restaurantOrderAnalysis).map((obj) => obj.pending),
    },
  ];

  const visitOptions = {
    chart: {
      id: "basic-line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },

    curve: "smooth",
  };

  const visitSeries = [
    {
      name: "Visits",
      data: restaurantStats?.visits
        ? Object.keys(restaurantStats)?.length > 0 &&
          Object.keys(restaurantStats?.visits)?.length > 0
          ? Object.entries(restaurantStats?.visits)
              .sort(([dateA], [dateB]) => {
                const [monthA, yearA] = dateA.split("-");
                const [monthB, yearB] = dateB.split("-");

                return (
                  new Date(yearA, parseInt(monthA) - 1) -
                  new Date(yearB, parseInt(monthB) - 1)
                );
              })
              .map(([, value]) => value)
          : []
        : [],
    },
  ];

  const visitRestaurantSeries = [
    {
      name: "Visits",
      data:
        Object.keys(restaurantVisits)?.length > 0
          ? Object.entries(restaurantVisits)
              ?.sort(([dateA], [dateB]) => {
                const [monthA, yearA] = dateA.split("-");
                const [monthB, yearB] = dateB.split("-");

                return (
                  new Date(yearA, parseInt(monthA) - 1) -
                  new Date(yearB, parseInt(monthB) - 1)
                );
              })
              ?.map(([, value]) => value)
          : [],
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.type == "restaurant") {
      dispatch(getRestaurantStatsById(user?.restaurantID));
      dispatch(getMostViewedProductsById(user?.restaurantID));
      dispatch(getLeastViewedProductsById(user?.restaurantID));
      dispatch(getBestSellersById(user?.restaurantID));
      dispatch(getWorstSellersById(user?.restaurantID));
      dispatch(getResturantRevenue(user?.restaurantID));
      dispatch(getOrderAnalysisById(user?.restaurantID));
      dispatch(getRestaurantVisitsById(user?.restaurantID));
    }
  }, []);

  const capitalizeKeys = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const revenueOptions = {
  //   chart: {
  //     type: "pie",
  //   },
  //   labels: Object.keys(restaurantRevenue).map((key) => capitalizeKeys(key)),
  //   legend: {
  //     fontFamily: "Poppins, sans-serif",
  //   },
  //   dataLabels: {
  //     enabled: true,
  //   },
  //   tooltip: {
  //     enabled: true,
  //   },
  // };
  // const revenueSeries = Object.values(restaurantRevenue);

  const combinedSellers = currentRestaurantBestSellers.concat(
    currentRestaurantWorstSellers
  );
  combinedSellers.sort((a, b) => a.orders - b.orders);
  const topProductsArray = combinedSellers.slice(0, 5);
  topProductsArray.reverse();

  const revenueOptions = {
    chart: {
      type: "pie",
    },
    labels: topProductsArray?.map((product) => capitalizeKeys(product.name)),
    legend: {
      fontFamily: "Poppins, sans-serif",
    },
    dataLabels: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
    },
  };

  const revenueSeries = [];
  topProductsArray?.forEach((product, index) => {
    revenueSeries[index] = product.orders;
  });

  const visitValues = Object.values(restaurantVisits);
  const totalVisits = visitValues.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const averageVisits = (totalVisits / 365).toFixed(2);

  useEffect(() => {
    let obj = {
      startDate: date?.startDate,
      endDate: date?.endDate,
      restaurantID: user?.restaurantID,
    };

    dispatch(getFilteredVisits(obj));
  }, [date]);

  return (
    <>
      <AllOrderCardModal toggle={toggle} modal={modal} />
      <TotalSalesModal toggles={toggles} modal={modals} />
      <Container fluid className="header  pb-8 pt-5 pt-md-7 ">
        <HeaderCards />
        <Container fluid>
          <Row className="chart mb-3 mt-1 ">
            <>
              {user?.type == "admin" ? (
                <>
                  {/* Order analysis and Revenue earned  */}
                  <Col xl={12}>
                    <Row className="row py-4  my-3 charts">
                      <Col md={8}>
                        <Card className="p-3 h-100">
                          <div className="d-lg-flex justify-content-between py-3 px-2">
                            <h3 className="mt-2 pl-3">Order Analysis</h3>

                            <div className="d-lg-flex align-items-center">
                              <div>
                                <span className="mx-2 fs-12">
                                  <svg
                                    className="mx-2"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="4"
                                      cy="4"
                                      r="4"
                                      fill="#26a0fc"
                                    />
                                  </svg>
                                  Active
                                </span>
                                <span className="mx-2 fs-12">
                                  <svg
                                    className="mx-2"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="4"
                                      cy="4"
                                      r="4"
                                      fill="#26e7a6"
                                    />
                                  </svg>
                                  Completed
                                </span>
                                <span className="mx-2 fs-12">
                                  <svg
                                    className="mx-2"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="4"
                                      cy="4"
                                      r="4"
                                      fill="#febc3b"
                                    />
                                  </svg>
                                  Pending
                                </span>
                              </div>
                            </div>
                          </div>
                          <LineChart
                            options={OrderAnalysisOptions}
                            series={OrderAnalysisSeries}
                          />
                        </Card>
                      </Col>
                      <Col md="4" xl="4">
                        <Card className="shadow pb-4 h-100">
                          <CardHeader className="mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div>
                                  <span className="fs-14">Total Customers</span>
                                </div>
                                <h2>
                                  {restaurantStats?.totalCustomers?.total || 0}
                                </h2>
                              </div>
                              {/* <i className="fa-solid fa-info"></i> */}
                            </div>
                          </CardHeader>
                          {loading ? (
                            <div>
                              <Spinner
                                className="d-flex mx-auto"
                                size="sm"
                                color="primary"
                              />
                            </div>
                          ) : (
                            <div className="d-flex flex-column justify-content-center h-100  w-100">
                              <PieChart
                                chartOptions={chartOptions}
                                chartSeries={chartSeries}
                                type="pie"
                              />
                            </div>
                          )}
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  {/* Top performing products and top Customers  */}
                  <Col xl={12}>
                    <Row>
                      <Col md="8" xl="8" className="mt-4">
                        <Card className="shadow mt-3">
                          <div className="d-lg-flex justify-content-between py-3 px-2">
                            <h3 className="mt-2 pl-3">
                              Top Performing Products
                            </h3>
                            {/* <div>
                              <div class="dropdown">
                                <button
                                  class="btn btn-outline  chart-btn dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Download
                                </button>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a class="dropdown-item" href="#">
                                    Day
                                  </a>
                                  <a class="dropdown-item" href="#">
                                    Month
                                  </a>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <BarChart
                            options={TopProducts}
                            series={ProductsSeries}
                          />
                        </Card>
                      </Col>
                      <Col md="4" xl="4" className="mt-4 h-100">
                        <Card className="shadow mt-3">
                          <CardHeader>
                            <div>
                              <span className="fs-14">Top Merchants</span>
                            </div>
                          </CardHeader>

                          <TopMerchants
                            topMerchantOptions={topMerchantOptions}
                            topMerchantSeries={topMerchantSeries}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  {/* Visit Chart  */}
                  <Col xl={12} className="my-4">
                    <Card>
                      <div className="d-flex justify-content-between mx-5 mt-4">
                        <div>
                          <h4 className="mb-0">Visits</h4>
                          <span className="text-muted fs-12">By Month</span>
                        </div>
                        {/* <div>
                          <h4 className="mb-0">14</h4>
                          <span className="text-muted fs-12">
                            Avg Daily Visits
                          </span>
                        </div> */}
                      </div>
                      {loading ? (
                        <div>
                          <Spinner
                            className="d-flex mx-auto"
                            size="sm"
                            color="primary"
                          />
                        </div>
                      ) : (
                        <LineChart
                          options={visitOptions}
                          series={visitSeries}
                        />
                      )}
                    </Card>
                  </Col>
                </>
              ) : (
                <>
                  {/* Order analysis and Revenue earned  */}
                  <Col xl={12}>
                    <Row className="py-4">
                      <Col md="8" xl="8">
                        <Card className="shadow mt-3 h-100">
                          {restaurantAnalysisLoader ? (
                            <CardBody className="d-flex justify-content-center align-items-center">
                              {" "}
                              <Spinner size="sm" color="primary" />
                            </CardBody>
                          ) : (
                            <>
                              {" "}
                              <div className="d-lg-flex justify-content-between py-3 px-2 ">
                                <h3 className="mt-2 pl-3">Order Analysis</h3>

                                <div className="d-lg-flex align-items-center">
                                  <div>
                                    <span className="mx-2 fs-12">
                                      <svg
                                        className="mx-2"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="4"
                                          fill="#26a0fc"
                                        />
                                      </svg>
                                      Active
                                    </span>
                                    <span className="mx-2 fs-12">
                                      <svg
                                        className="mx-2"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="4"
                                          fill="#26e7a6"
                                        />
                                      </svg>
                                      Completed
                                    </span>
                                    <span className="mx-2 fs-12">
                                      <svg
                                        className="mx-2"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="4"
                                          fill="#febc3b"
                                        />
                                      </svg>
                                      Pending
                                    </span>
                                  </div>

                                  {/* <div class="dropdown">
                                    <button
                                      class="btn btn-outline  chart-btn dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Monthly
                                    </button>
                                    <div
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a class="dropdown-item" href="#">
                                        Day
                                      </a>
                                      <a class="dropdown-item" href="#">
                                        Month
                                      </a>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <LineChart
                                options={OrderAnalysisOptions}
                                series={restaurantAnalysisSeries}
                              />
                            </>
                          )}
                        </Card>
                      </Col>
                      <Col md="4" xl="4">
                        <Card className="shadow mt-3 py-2 mb-3 h-100">
                          {revenueLoader ? (
                            <CardBody className="d-flex justify-content-center align-items-center">
                              <Spinner size="sm" color="primary" />
                            </CardBody>
                          ) : (
                            <>
                              <CardHeader className="mb-4">
                                <div>
                                  <span className="fs-14">Revenue Earned</span>
                                </div>
                                <h2>{restaurantCurrency}{totalRevenue ? totalRevenue : 0}</h2>
                              </CardHeader>
                              <div className="d-flex flex-column justify-content-center h-100  w-100">
                                <PieChart
                                  chartOptions={revenueOptions}
                                  chartSeries={revenueSeries}
                                  type="pie"
                                />
                              </div>
                            </>
                          )}
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={12}>
                    <Row>
                      <Col md="12" xl="6" className="mt-4">
                        <Card className="shadow mt-3">
                          <h3 className="mt-2 pl-3">Best Sellers</h3>
                          <BarChart
                            options={bestSellers}
                            series={bestSellersSeries}
                          />
                        </Card>
                      </Col>
                      <Col md="12" xl="6" className="mt-4">
                        <Card className="shadow mt-3 ">
                          <h3 className="mt-2 pl-3">Worst Sellers</h3>
                          <BarChart
                            options={worstSeller}
                            series={worstSellerSeries}
                          />
                        </Card>
                      </Col>
                      <Col md="12" xl="6" className="mt-4">
                        <Card className="shadow mt-3 ">
                          <h3 className="mt-2 pl-5">Most Viewed Items</h3>
                          <BarChart
                            options={mostViewedProducts}
                            series={mostViewedSeries}
                          />
                        </Card>
                      </Col>

                      <Col md="12" xl="6" className="mt-4">
                        <Card className="shadow mt-3 ">
                          <h3 className="mt-2 pl-3">Least Viewed Items</h3>
                          <BarChart
                            options={leastViewedProducts}
                            series={leastViewedSeries}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  {/* Top performing products and top Customers  */}
                  {/* <Col xl={12}>
                    <Row>
                      <Col md="8" xl="8" className="mt-4">
                        <Card className="shadow mt-3">
                          <div className="d-lg-flex justify-content-between py-3 px-2">
                            <h3 className="mt-2 pl-3">
                              Top Performing Products
                            </h3>
                            <div>
                              <div class="dropdown">
                                <button
                                  class="btn btn-outline  chart-btn dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Download
                                </button>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a class="dropdown-item" href="#">
                                    Day
                                  </a>
                                  <a class="dropdown-item" href="#">
                                    Month
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <BarChart
                            options={TopProducts}
                            series={ProductsSeries}
                          />
                        </Card>
                      </Col>
                      <Col md="4" xl="4" className="mt-4 h-100">
                        <Card className="shadow mt-3 ">
                          <div className="d-flex justify-content-between align-items-center p-3">
                            <div>
                              <div>
                                <span className="fs-14">Top Customers</span>
                              </div>
                              <h2>5.987,34</h2>
                            </div>

                            <div className="d-flex flex-column">
                              <span className="mx-2 fs-12">
                                <svg
                                  className="mx-2"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#19CB98" />
                                </svg>
                                New Customers
                              </span>
                              <span className="mx-2 fs-12">
                                <svg
                                  className="mx-2"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="4" cy="4" r="4" fill="#19CB98" />
                                </svg>
                                Returning Customers
                              </span>
                            </div>
                          </div>

                          <TotalCustomerChart />
                        </Card>
                      </Col>
                    </Row>
                  </Col> */}
                  {/* Visit Chart  */}
                  <Col xl={12} className="my-4">
                    <Card>
                      {visitsLoader ? (
                        <CardBody className="d-flex justify-content-center align-items-center">
                          <Spinner color="primary" size="sm" />
                        </CardBody>
                      ) : (
                        <>
                          {" "}
                          <div className="d-flex justify-content-between align-items-center mx-5 mt-4">
                            <div>
                              <h4 className="mb-0">Visits</h4>
                              <span className="text-muted fs-12">By Month</span>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <Row className="d-flex justify-content-between align-items-center mb-2">
                                <Input
                                  type="date"
                                  className="visit_input"
                                  value={date.startDate}
                                  onChange={(e) =>
                                    setDate({
                                      ...date,
                                      startDate: e.target.value,
                                    })
                                  }
                                />
                                <Input
                                  type="date"
                                  className="visit_input"
                                  value={date.endDate}
                                  onChange={(e) =>
                                    setDate({
                                      ...date,
                                      endDate: e.target.value,
                                    })
                                  }
                                />
                              </Row>
                              <h4 className="mb-0">
                                {dailyVists ? dailyVists : averageVisits}
                              </h4>
                              <span className="text-muted fs-12">
                                Avg Daily Visits
                              </span>
                            </div>
                          </div>
                          <LineChart
                            options={visitOptions}
                            series={visitRestaurantSeries}
                          />
                        </>
                      )}
                    </Card>
                  </Col>
                </>
              )}
            </>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Header;
