import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { TEST_CLIENT_KEY } from "../contants/index.js";
import { useState } from "react";
import { Button, Form, FormGroup, Label, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSubscribe } from "store/actions/subscriptionAction.js";
import { RepositoryFactory } from "repository/RepositoryFactory.js";
let stripePromise = loadStripe(TEST_CLIENT_KEY);
let Payment = RepositoryFactory.get("payment");

function Stripe({
  subscriptionType,
  setSubscriptionType,
  setShowStripe,
  couponid,
  validCoupon,
  discountedAmount,
}) {
  const dispatch = useDispatch();
  const { user, uid } = useSelector((state) => state.auth);
  const { successMsg, setSuccessMsg } = useState("");
  const [stripeError, setStripeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const handleStripError = (message) => {
    setStripeError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setStripeError(false);
      setErrorMessage("");
    }, 3000);
  };

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await setPaymentLoader(true);
              const cardElement = await elements.getElement(CardNumberElement);
              const cardElement2 = await elements.getElement(CardExpiryElement);
              const cardElement3 = await elements.getElement(CardCvcElement);

              const { error, paymentMethod } = await stripe.createPaymentMethod(
                {
                  type: "card",
                  card: cardElement,
                  card: cardElement2,
                  card: cardElement3,
                }
              );
              if (error) {
                await setPaymentLoader(false);
                alert("Card Details not Added");
              } else {
                const result = await stripe.createToken(cardElement);
                if (result.error) {
                  alert(result.error.message);
                  await setPaymentLoader(false);
                  return;
                }

                let planId = "";
                await Payment.getPaymentPrices()
                  .then((res) => {
                    let { data } = res;
                    data?.data?.forEach((record) => {
                      let nickName = record?.nickname;
                      let subscription = nickName?.split(" ");
                      let selectedSubscription =
                        subscription?.[0].toLowerCase();
                      if (selectedSubscription === subscriptionType) {
                        planId = record?.id;
                      }
                    });
                  })
                  .catch((err) => {
                    let { data } = err.response;
                    console.error(data?.error, "Error");
                    setPaymentLoader(false);
                  });
                dispatch(
                  createSubscribe(
                    {
                      email: user?.email,
                      stripeToken: result.token.id,
                      plan: subscriptionType,
                      priceId: planId,
                      coupon: couponid,
                    },
                    uid,
                    (res) => {
                      elements.getElement(CardNumberElement).clear();
                      elements.getElement(CardExpiryElement).clear();
                      elements.getElement(CardCvcElement).clear();
                      setSubscriptionType("");
                      setPaymentLoader(false);
                      setShowStripe(false);
                    },
                    () => {
                      setPaymentLoader(false);
                    }
                  )
                );
              }
            }}
          >
            <FormGroup>
              <Label className="form_label">Card number</Label>
              <div
                className="form-control mt-2 d-flex"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <i className="fa fa-credit-card"></i>
                <div style={{ flexBasis: "90%" }}>
                  <CardNumberElement
                    required
                    options={{
                      placeholder: "1234 1234 1234 1234",
                      style: {
                        base: {
                          // backgroundColor: "#232733",
                          fontSize: "16px",
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </FormGroup>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="form_label">Expiry Date</Label>
                  <div
                    className="form-control mt-2 d-flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <i className="fa fa-calendar"></i>
                    <div
                      style={{
                        flexBasis: "90%",
                      }}
                    >
                      <CardExpiryElement
                        required
                        options={{
                          placeholder: "MM/YY",
                          style: {
                            base: {
                              fontSize: "16px",
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="form_label">CVC/CVV</Label>
                  <div
                    className="form-control mt-2 d-flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "80%",
                      }}
                    >
                      <CardCvcElement
                        required
                        options={{
                          placeholder: "...",
                          style: {
                            base: {
                              fontSize: "16px",
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
                {stripeError && (
                  <p className="mb-0 my-1 text-danger">{errorMessage}</p>
                )}
                {successMsg && (
                  <p className="mb-0 my-1 text-success">{successMsg}</p>
                )}
              </div>
            </div>{" "}
            {subscriptionType && (
              <h4>
                {subscriptionType === "monthly" ? "Monthly" : "Yearly"}{" "}
                Subscription :{" "}
                {subscriptionType === "monthly" ? "29.99" : "300"}$
              </h4>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <h4>
                Discounted:{" "}
                {subscriptionType === "monthly"
                  ? 29.99 - discountedAmount
                  : 300 - discountedAmount}
                $
              </h4>
              <h4 className="mr-2">
                Total Payable Amount: {discountedAmount}$
              </h4>
            </div>
            {}
            <Button
              className="auth-button mt-2 py-3 btn btn-lg btn-block border border-none"
              type="submit"
              style={{ backgroundColor: "#f2ba36" }}
              disabled={paymentLoader && couponid && !validCoupon}
            >
              {paymentLoader ? (
                <Spinner size="sm" />
              ) : (
                <h4 className="mb-0">Subscribe</h4>
              )}
            </Button>
          </Form>
        )}
      </ElementsConsumer>
    </Elements>
  );
}

export default Stripe;
