/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import React from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import play from "../../assets/img/play.svg";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/authActions";
import arrowLeft from "../../assets/img/icons/arrowLeft.svg";
import NextLogo from "../../assets/img/Mpink.png";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

var ps;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { hide, setHide } = props;
  const [collapseOpen, setCollapseOpen] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const toggle1 = () => setIsOpen(!isOpen);
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = (value) => {
    setCollapseOpen(false);
    setIsActive(value);
  };
  const { user, userPermissions } = useSelector((state) => state.auth);
  const { restaurantMedia } = useSelector((state) => state.restaurant);

  const [dropdown, setDropDown] = useState(false);
  const toggle = () => {
    setDropDown(!dropdown);
  };

  useEffect(() => {
    if (
      location.pathname == "/admin/settings" ||
      location.pathname == "/admin/accout-settings" ||
      location.pathname == "/admin/client-setting-menu" ||
      location.pathname == "/admin/payment-connection" ||
      location.pathname == "/admin/client-settings" ||
      location.pathname == "/admin/venue-setting-menu" ||
      location.pathname == "/admin/store-timings" ||
      location.pathname == "/admin/pickup-or-delivery" ||
      location.pathname == "/admin/holidays" ||
      location.pathname == "/admin/venue-restaurant-language" ||
      location.pathname == "/admin/venue-restaurant-currency"
    ) {
      setSettingsOpen(true);
    } else {
      setSettingsOpen(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    let menuID = localStorage.getItem("menuID");
    if (
      location.pathname == "/admin/new-menu" ||
      location.pathname == `/admin/edit-menu/${menuID}`
    ) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, [location.pathname]);
  // creates the links that appear in the left menu / Sidebar

  const createLinks = (routes) => {
    if (user && user?.type == "restaurant") {
      return routes.map((prop, key) => {
        if (prop.isMenu !== false) {
          if (prop.name === "Dashboard" || user?.type == prop.type) {
            if (
              prop.path === "/subscriptions" &&
              user?.subScriptionStatus !== "unsubscribe"
            ) {
              return null;
            }
            if (
              prop.path == "/menus" ||
              prop.path == "/payment-connection" ||
              prop.path == "/store-timings" ||
              prop.path == "/pickup-or-delivery" ||
              prop.path == "/category" ||
              prop.path == "/holidays" ||
              prop.path == "/labels" ||
              prop.path == "/client-settings" ||
              prop.path == "/accout-settings" ||
              prop.path == "/client-setting-menu" ||
              prop.path == "/venue-setting-menu" ||
              prop.path == "/venue-restaurant-language" ||
              prop.path == "/venue-restaurant-currency" ||
              prop.path == "/notifications" ||
              prop.path == "/venue-settings"
            ) {
              return (
                <React.Fragment key={key}>
                  {user?.subScriptionStatus != "unsubscribe" &&
                    prop.path == "/menus" && (
                      <>
                        <UncontrolledDropdown
                          nav
                          inNavbar
                          className=""
                        ></UncontrolledDropdown>
                      </>
                    )}
                </React.Fragment>
              );
            } else {
              return (
                <NavItem key={key}>
                  {user?.subScriptionStatus == "unsubscribe" ? (
                    prop.name == "Subscriptions" ? (
                      <NavLink
                        to={prop.layout + prop.path}
                        tag={NavLinkRRD}
                        // activeClassName="active"
                        onClick={() => closeCollapse(key)}
                        style={
                          location.pathname === prop.layout + prop.path
                            ? restaurantMedia.adminColor
                              ? {
                                  backgroundColor: restaurantMedia.adminColor,
                                }
                              : {
                                  backgroundColor: "#d70365",
                                }
                            : {}
                        }
                        className={
                          location.pathname == prop.layout + prop.path
                            ? "nav-link__active"
                            : ""
                        }
                      >
                        <img
                          src={
                            location.pathname == prop.layout + prop.path
                              ? prop.activeIcon
                              : prop.icon
                          }
                          alt="path image"
                          className="mx-2"
                        />

                        {!hide && (
                          <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                            {prop.name}
                          </p>
                        )}
                      </NavLink>
                    ) : (
                      ""
                    )
                  ) : (
                    <>
                      {prop.name == "Settings" ? (
                        <NavLink
                          to={prop.layout + prop.path}
                          tag={NavLinkRRD}
                          // activeClassName="active"
                          onClick={() =>
                            closeCollapse(prop.layout + prop.path + prop.name)
                          }
                          style={
                            settingsOpen
                              ? restaurantMedia?.adminColor
                                ? {
                                    backgroundColor: restaurantMedia.adminColor,
                                  }
                                : {
                                    backgroundColor: "#d70365",
                                  }
                              : {}
                          }
                          className={settingsOpen ? "nav-link__active" : ""}
                        >
                          <img
                            src={settingsOpen ? prop.activeIcon : prop.icon}
                            alt="active path"
                            className="mx-2"
                          />

                          {!hide && (
                            <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                              {prop.name}
                            </p>
                          )}
                        </NavLink>
                      ) : prop.name == "Menu" ? (
                        <>
                          {" "}
                          <NavLink
                            to={prop.layout + prop.path}
                            tag={NavLinkRRD}
                            style={
                              menuOpen
                                ? restaurantMedia.adminColor
                                  ? {
                                      backgroundColor:
                                        restaurantMedia.adminColor,
                                    }
                                  : {
                                      backgroundColor: "#d70365",
                                    }
                                : {}
                            }
                            className={menuOpen ? "nav-link__active" : ""}
                          >
                            <img
                              src={menuOpen ? prop.activeIcon : prop.icon}
                              alt="menu"
                              className="mx-2"
                            />

                            {!hide && (
                              <p
                                className="mb-0"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {prop.name}
                              </p>
                            )}
                          </NavLink>
                        </>
                      ) : (
                        <>
                          {" "}
                          <NavLink
                            to={prop.layout + prop.path}
                            tag={NavLinkRRD}
                            style={
                              location.pathname === prop.layout + prop.path
                                ? restaurantMedia.adminColor
                                  ? {
                                      backgroundColor:
                                        restaurantMedia.adminColor,
                                    }
                                  : {
                                      backgroundColor: "#d70365",
                                    }
                                : {}
                            }
                            className={
                              location.pathname == prop.layout + prop.path
                                ? "nav-link__active"
                                : ""
                            }
                          >
                            <img
                              src={
                                location.pathname == prop.layout + prop.path
                                  ? prop.activeIcon
                                  : prop.icon
                              }
                              alt="path icon"
                              className="mx-2"
                            />

                            {!hide && (
                              <p
                                className="mb-0"
                                style={{ fontSize: "0.9rem" }}
                              >
                                {prop.name}
                              </p>
                            )}
                          </NavLink>
                        </>
                      )}
                    </>
                  )}
                </NavItem>
              );
            }
          }
        }
      });
    } else if (user && user?.type == "kitchen-admin") {
      return routes.map((prop, key) => {
        if (prop.isMenu !== false) {
          if (prop.name == "Dashboard") {
            return (
              <NavItem key={key}>
                <NavLink
                  to={prop.layout + prop.path}
                  tag={NavLinkRRD}
                  // activeClassName="active"
                  onClick={() => closeCollapse(key)}
                  style={
                    location.pathname == prop.layout + prop.path
                      ? restaurantMedia.adminColor
                        ? {
                            background: restaurantMedia.adminColor,
                          }
                        : { background: "#d70365" }
                      : {}
                  }
                  className={
                    location.pathname == prop.layout + prop.path
                      ? "nav-link__active"
                      : ""
                  }
                >
                  <img
                    src={
                      location.pathname == prop.layout + prop.path
                        ? prop.activeIcon
                        : prop.icon
                    }
                    alt="path icon"
                    className="mx-2"
                  />

                  {!hide && (
                    <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                      {prop.name}
                    </p>
                  )}
                </NavLink>
              </NavItem>
            );
          } else if (user && userPermissions) {
            for (const perm in userPermissions) {
              const old_path = prop.path;
              const path = old_path.split("/")[1];
              if (perm == path || path == "new-menu") {
                if (prop.name == "Menu") {
                  return (
                    <NavLink
                      key={key}
                      to={prop.layout + prop.path}
                      tag={NavLinkRRD}
                      style={
                        menuOpen
                          ? restaurantMedia.adminColor
                            ? {
                                backgroundColor: restaurantMedia.adminColor,
                              }
                            : {
                                backgroundColor: "#d70365",
                              }
                          : {}
                      }
                      className={menuOpen ? "nav-link__active" : ""}
                    >
                      <img
                        src={menuOpen ? prop.activeIcon : prop.icon}
                        alt="menu"
                        className="mx-2"
                      />

                      {!hide && (
                        <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                          {prop.name}
                        </p>
                      )}
                    </NavLink>
                  );
                } else {
                  return (
                    <NavItem key={key}>
                      <NavLink
                        to={prop.layout + prop.path}
                        tag={NavLinkRRD}
                        // activeClassName="active"
                        onClick={() => closeCollapse(key)}
                        style={
                          location.pathname == prop.layout + prop.path
                            ? restaurantMedia.adminColor
                              ? {
                                  background: restaurantMedia.adminColor,
                                }
                              : {
                                  background: "#d70365",
                                }
                            : {}
                        }
                        className={
                          location.pathname == prop.layout + prop.path
                            ? "nav-link__active"
                            : ""
                        }
                      >
                        <img
                          src={
                            location.pathname == prop.layout + prop.path
                              ? prop.activeIcon
                              : prop.icon
                          }
                          alt="path icon"
                          className="mx-2"
                        />

                        {!hide && (
                          <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                            {prop.name}
                          </p>
                        )}
                      </NavLink>
                    </NavItem>
                  );
                }
              }
            }
          }
        }
      });
    } else if (user && user?.type === "kitchen-cook") {
      return routes.map((prop, key) => {
        if (prop.isMenu !== false) {
          if (prop.name == "Dashboard") {
            return null;
            // return (
            //   <NavItem key={key}>
            //     <NavLink
            //       to={prop.layout + prop.path}
            //       tag={NavLinkRRD}
            //       // activeClassName="active"
            //       onClick={() => closeCollapse(key)}
            //       style={
            //         location.pathname == prop.layout + prop.path
            //           ? restaurantMedia.adminColor
            //             ? {
            //                 background: restaurantMedia.adminColor,
            //               }
            //             : {
            //                 background: "#d70365",
            //               }
            //           : {}
            //       }
            //       className={
            //         location.pathname == prop.layout + prop.path
            //           ? "nav-link__active"
            //           : ""
            //       }
            //     >
            //       <img
            //         src={
            //           location.pathname == prop.layout + prop.path
            //             ? prop.activeIcon
            //             : prop.icon
            //         }
            //         alt="path icon"
            //         className="mx-2"
            //       />

            //       {!hide && (
            //         <p className="mb-0" style={{ fontSize: "0.9rem" }}>
            //           {prop.name}
            //         </p>
            //       )}
            //     </NavLink>
            //   </NavItem>
            // );
          } else if (user && userPermissions) {
            for (const perm in userPermissions) {
              const old_path = prop.path;
              const path = old_path.split("/")[1];
              if (perm == path) {
                return (
                  <NavItem key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      tag={NavLinkRRD}
                      // activeClassName="active"
                      onClick={() => closeCollapse(key)}
                      style={
                        location.pathname == prop.layout + prop.path
                          ? restaurantMedia.adminColor
                            ? {
                                background: restaurantMedia.adminColor,
                              }
                            : {
                                background: "#d70365",
                              }
                          : {}
                      }
                      className={
                        location.pathname == prop.layout + prop.path
                          ? "nav-link__active"
                          : ""
                      }
                    >
                      <img
                        src={
                          location.pathname == prop.layout + prop.path
                            ? prop.activeIcon
                            : prop.icon
                        }
                        alt="path icon"
                        className="mx-2"
                      />

                      {!hide && (
                        <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                          {prop.name}
                        </p>
                      )}
                    </NavLink>
                  </NavItem>
                );
              }
            }
          }
        }
      });
    } else if (user && user?.type == "admin" && user?.role == "analyst") {
      return routes.map((prop, key) => {
        if (prop.name == "Dashboard") {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                // activeClassName="active"
                onClick={() => closeCollapse(key)}
                style={
                  location.pathname == prop.layout + prop.path
                    ? restaurantMedia.adminColor
                      ? {
                          background: restaurantMedia.adminColor,
                        }
                      : {
                          background: "#d70365",
                        }
                    : {}
                }
                className={
                  location.pathname == prop.layout + prop.path
                    ? "nav-link__active"
                    : ""
                }
              >
                <img
                  src={
                    location.pathname == prop.layout + prop.path
                      ? prop.activeIcon
                      : prop.icon
                  }
                  alt="path icon"
                  className="mx-2"
                />

                {!hide && (
                  <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                    {prop.name}
                  </p>
                )}
              </NavLink>
            </NavItem>
          );
        }
      });
    } else {
      return routes.map((prop, key) => {
        if (prop.isMenu !== false) {
          if (prop.name === "Dashboard" || user?.type == prop.type) {
            if (
              prop.path == "/menus" ||
              prop.path == "/payment-connection" ||
              prop.path == "/store-timings" ||
              prop.path == "/pickup-or-delivery" ||
              prop.path == "/category" ||
              prop.path == "/holidays" ||
              prop.path == "/labels" ||
              prop.path == "/client-settings" ||
              prop.path == "/accout-settings" ||
              prop.path == "/venue-settings"
              // prop.path == '/customization'
            ) {
              return (
                <>
                  {prop.path == "/menus" && (
                    <>
                      <UncontrolledDropdown key={key} nav inNavbar className="">
                        <DropdownToggle
                          nav
                          caret
                          onClick={toggle1}
                          className="dropdown-togglenavbar"
                        >
                          <img src={prop.icon} alt="menu" className="mx-2" />
                          {!hide && (
                            <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                              {prop.name}
                            </p>
                          )}
                        </DropdownToggle>

                        <Collapse
                          isOpen={isOpen}
                          className={`${hide ? "" : "sidebar-collapse"}`}
                        >
                          <NavItem>
                            <NavLink
                              to={"/admin" + "/menus"}
                              tag={NavLinkRRD}
                              onClick={() => closeCollapse("menus")}
                              // activeClassName="active "
                              style={
                                location.pathname == "/admin/menus"
                                  ? restaurantMedia.adminColor
                                    ? {
                                        background: restaurantMedia.adminColor,
                                      }
                                    : {
                                        background: "#d70365",
                                      }
                                  : {}
                              }
                              className={`${
                                location.pathname == "/admin/menus"
                                  ? "nav-link__active"
                                  : ""
                              } d-flex justify-content-between align-items-center links`}
                            >
                              Menu
                              <img
                                src={arrowLeft}
                                alt="arrow"
                                className={`${
                                  !hide && location.pathname == "/admin/menus"
                                    ? ""
                                    : "d-none"
                                }`}
                              />
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to={"/admin" + "/category"}
                              tag={NavLinkRRD}
                              onClick={() => closeCollapse("category")}
                              style={
                                location.pathname == "/admin/category"
                                  ? restaurantMedia.adminColor
                                    ? {
                                        background: restaurantMedia.adminColor,
                                      }
                                    : {
                                        background: "#d70365",
                                      }
                                  : {}
                              }
                              className={`${
                                location.pathname == "/admin/category"
                                  ? "nav-link__active"
                                  : ""
                              } d-flex justify-content-between align-items-center links`}
                            >
                              Category
                              <img
                                src={arrowLeft}
                                alt="arrow"
                                className={`${
                                  !hide &&
                                  location.pathname == "/admin/category"
                                    ? ""
                                    : "d-none"
                                }`}
                              />
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to={"/admin" + "/labels"}
                              tag={NavLinkRRD}
                              onClick={() => closeCollapse("labels")}
                              style={
                                location.pathname == "/admin/labels"
                                  ? restaurantMedia.adminColor
                                    ? {
                                        background: restaurantMedia.adminColor,
                                      }
                                    : {
                                        background: "#d70365",
                                      }
                                  : {}
                              }
                              className={`${
                                location.pathname == "/admin/labels"
                                  ? "nav-link__active"
                                  : ""
                              } d-flex justify-content-between align-items-center links`}
                            >
                              Label
                              <img
                                src={arrowLeft}
                                alt="aroow"
                                className={`${
                                  !hide && location.pathname == "/admin/labels"
                                    ? ""
                                    : "d-none"
                                }`}
                              />
                            </NavLink>
                          </NavItem>
                        </Collapse>
                      </UncontrolledDropdown>
                    </>
                  )}
                </>
              );
            } else {
              return (
                <NavItem key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    tag={NavLinkRRD}
                    // activeClassName="active"
                    onClick={() => closeCollapse(key)}
                    style={
                      location.pathname == prop.layout + prop.path
                        ? restaurantMedia.adminColor
                          ? {
                              background: restaurantMedia.adminColor,
                            }
                          : {
                              background: "#d70365",
                            }
                        : {}
                    }
                    className={
                      location.pathname == prop.layout + prop.path
                        ? "nav-link__active"
                        : ""
                    }
                  >
                    <img
                      src={
                        location.pathname == prop.layout + prop.path
                          ? prop.activeIcon
                          : prop.icon
                      }
                      alt="path icon"
                      className="mx-2"
                    />

                    {!hide && (
                      <p className="mb-0" style={{ fontSize: "0.9rem" }}>
                        {prop.name}
                      </p>
                    )}
                  </NavLink>
                </NavItem>
              );
            }
          }
        }
      });
    }
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={`navbar-vertical fixed-left navbar-light bg-white ${
        hide ? "hidden" : ""
      }`}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Brand */}
        <div className="brand">
          {logo ? (
            <NavbarBrand className="pt-2 pb-0 " {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
        </div>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    className="img-fluid"
                    alt="logo"
                    src={user?.restaurantLogo || NextLogo}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow " right>
              <DropdownItem
                onClick={() => {
                  dispatch(logout());
                }}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo?.innerLink ? (
                    <Link to={logo?.innerLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo?.outterLink}>
                      <img alt={logo?.imgAlt} src={logo?.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
          <Nav navbar className="mt-auto">
            {user?.type != "admin" && (
              <NavItem className="my-2">
                <NavLink
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    window.open(`https://play.google.com/store/apps`, "_blank");
                  }}
                  style={{
                    // backgroundColor: restaurantMedia?.adminColor || "#d70365",
                    border: `2px solid ${
                      restaurantMedia?.adminColor || "#d70365"
                    }`,
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={play}
                    alt="SubItem image"
                    height={40}
                    width={40}
                    onClick={() => setEditingIndex(index)}
                  />

                  {!hide && (
                    <p
                      className="mb-0 mt-1 text-uppercase"
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Download APP
                    </p>
                  )}
                </NavLink>
              </NavItem>
            )}
            {user?.type != "admin" && (
              <NavItem className="my-2">
                <NavLink
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    window.open(
                      `https://nexteats-main.web.app/restaurant/${user?.restaurantID}`,
                      "_blank"
                    );
                  }}
                  style={{
                    // backgroundColor: restaurantMedia?.adminColor || "#d70365",
                    border: `2px solid ${
                      restaurantMedia?.adminColor || "#d70365"
                    }`,
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#64748b"
                    className="mx-2"
                    style={{ width: "25px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>

                  {!hide && (
                    <p
                      className="mb-0 mt-1 text-uppercase"
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Preview Restaurant
                    </p>
                  )}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
