import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  Row,
  Button,
  Form,
  Label,
  Input,
  FormGroup,
  Spinner,
} from "reactstrap";
import { updateEmail } from "store/actions/settingAction";
import { updateRestaurantProfile } from "store/actions/settingAction";
import SocialProfileTable from "./SocialProfileTable";
import { Subscription } from "views/Subscription";

const AccoutSettingTable = ({
  data,
  addToggle,
  editToggle,
  deleteToggle,
  setEditData,
  setIndex,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, uid } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.setting);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    location: "",
    description:"",
    subScriptionStatus: "",
  });
  const [emailError, setEmailError] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
      setEmailError("");
    setFormData({ ...formData, [name]: value });
    if (name === "email" && !validateEmail(value)) {
      setEmailError("Please enter a valid email address (example@gmail.com)");
    } else {
      setEmailError("");
    }
  };

  const handleProfileUpdate = (evt, elements, stripe) => {
    evt.preventDefault();
    const {email}=formData;
    if(!validateEmail(email)){
      return;
    }
    else{
      dispatch(updateRestaurantProfile(uid, formData));
    }
  };

  useEffect(() => {
    setFormData({
      name: user?.name,
      email: user?.email,
      subScriptionStatus: user?.subScriptionStatus
        ? user?.subScriptionStatus
        : "",
        description:user?.description||"",
      location: user?.location,
    });
  }, [user]);
  const validateEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)&&!email.includes(" ");
};
  return (
    <>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/settings");
                  }}
                >
                  <i className="fas fa-arrow-left "></i>
                </Button>

                <h3 className=" pt-2 ">Account Settings</h3>
              </div>
            </CardHeader>
            <div className="mx-3 mx-md-5 my-2">
              <h3 className=" pt-2 ">Update Restaurant Profile</h3>

              <Form onSubmit={(evt) => handleProfileUpdate(evt)}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="name"
                    required
                    placeholder="Enter Your Restaurant Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Update Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {emailError ? (
                    <span className="text-danger" style={{ fontSize: "11px" }}>
                      {emailError}
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Location</Label>
                  <Input
                    type="text"
                    name="location"
                    required
                    placeholder="Enter Your Location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Detail Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    required
                    placeholder="Enter Restaurant Detail Description..."
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="custom-class"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <Spinner size="sm" /> : "Update"}
                  </Button>
                </div>
              </Form>
              <SocialProfileTable
                data={data}
                addToggle={addToggle}
                editToggle={editToggle}
                deleteToggle={deleteToggle}
                setEditData={setEditData}
                setIndex={setIndex}
              />
            </div>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default AccoutSettingTable;
