import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
let Stats = RepositoryFactory.get("stats");
export const getPeakHours = (payload) => async (dispatch) => {
  dispatch(peakHoursLoader(true));
  try {
    const { data } = await Stats.getPeakHours(payload);
    if (data.success) {
      dispatch({
        type: "GET_PEAK_HOURS",
        payload: data?.data,
      });
      dispatch(peakHoursLoader(false));
    } else {
      toast.error(data.message);
      console.log(data.message);
      dispatch(peakHoursLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(peakHoursLoader(false));
  }
};

export const getTotalCustomers = (payload) => async (dispatch) => {
  dispatch(customerLoader(true));
  try {
    const { data } = await Stats.getTotalCustomers(payload);
    if (data.success) {
      dispatch({
        type: "GET_TOTAL_CUSTOMERS",
        payload: data?.data,
      });
      dispatch(customerLoader(false));
    } else {
      toast.error(data.message);
      console.log(data.message);
      dispatch(customerLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(customerLoader(false));
  }
};
export const getTotalMethodPercentage = (payload) => async (dispatch) => {
  dispatch(paymentLoader(true));
  try {
    const { data } = await Stats.getPaymentMethodPercentage(payload);

    if (data.success) {
      dispatch({
        type: "GET_PAYMENT_METHODS",
        payload: data?.data,
      });
      dispatch(paymentLoader(false));
    } else {
      alert(data.message);
      dispatch(paymentLoader(false));
    }
  } catch (error) {
    alert(error.message);
    dispatch(paymentLoader(false));
  }
};

export const getCustomersDemographics = (payload) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const { data } = await Stats.getRestaurantCustomerDemographics(payload);

    if (data.success) {
      dispatch({
        type: "GET_CUSTOMERS_DEMOGRAPHICS",
        payload: data,
      });
      dispatch(Loader(false));
    } else {
      alert(data.message);
      dispatch(Loader(false));
    }
  } catch (error) {
    alert(error.message);
    dispatch(Loader(false));
  }
};
export const getOrderFrequency = (payload) => async (dispatch) => {
  dispatch(frequencyLoader(true));
  try {
    const { data } = await Stats.getOrderFrequency(payload);
    if (data.success) {
      dispatch({
        type: "GET_ORDER_FREQUENCY",
        payload: data?.data,
      });
      dispatch(frequencyLoader(false));
    } else {
      alert(data.message);
      dispatch(frequencyLoader(false));
    }
  } catch (error) {
    alert(error.message);
    dispatch(frequencyLoader(false));
  }
};

export const getRestaurantStats = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .get()
      .then((data) => {
        dispatch({
          type: "GET_RESTAURANTS_STATS",
          payload: data?.data(),
        });
        dispatch(Loader(false));
      })
      .catch((err) => {
        alert(err.message);
        dispatch(Loader(false));
      });
  } catch (error) {
    alert(error.message);
    dispatch(Loader(false));
  }
};

export const getRestaurantStatsById = (id) => async (dispatch) => {
  console.log(id, "restaurantID");
  dispatch(Loader(true));
  try {
    const Data = await firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("restaurantStats")
      .doc(id)
      .get()
      .then((data) => {
        return data;
        //   if(data.exists){
        //   dispatch({
        //     type: "GET_RESTAURANT_STATS_BY_ID",
        //     payload: data?.data(),
        //   });
        //   dispatch(Loader(false));
        // }
      })
      .catch((err) => {
        dispatch(Loader(false));
        alert("restaurantData can't fetched");
        dispatch(Loader(false));
      });
    console.log(Data, "DATA DATA DATA DATA>>>>>>>>>");
    console.log(Data?._delegate.data(), "DATA>>>>>>>>>>>>");
    if (Data.exists) {
      dispatch({
        type: "GET_RESTAURANT_STATS_BY_ID",
        payload: Data?._delegate.data(),
      });
      dispatch(Loader(false));
    }
  } catch (error) {
    toast.error("Restaurant Stats not found");
    console.log(error.message);
    dispatch(Loader(false));
  }
};
export const getMostViewedProductsById = (id) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const temp = [];
    const data = await firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topPerformingProducts")
      .where("restaurantID", "==", id)
      .orderBy("views", "desc")
      .get();
    let records =
      data?.docs?.length > 1 ? data?.docs?.length / 2 : data?.docs?.length;
    for (
      let i = 0;
      i < data?.docs?.length &&
      temp?.length < parseInt(records) &&
      temp?.length < 5;
      i++
    ) {
      data?.docs[i]?.data()?.name !== "" &&
        temp.push({
          id: data?.docs[i]?.id,
          ...data?.docs[i]?.data(),
        });
    }

    dispatch({
      type: "GET_MOST_VIEWED_PRODUCTS_BY_ID",
      payload: temp,
    });
    dispatch(Loader(false));
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);

    dispatch(Loader(false));
  }
};
export const getLeastViewedProductsById = (id) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const temp = [];
    const data = await firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topPerformingProducts")
      .where("restaurantID", "==", id)
      .orderBy("views", "asc")
      .get();
    let records =
      data?.docs?.length > 1 ? data?.docs?.length / 2 : data?.docs?.length;
    for (
      let i = 0;
      i < data?.docs?.length &&
      temp?.length < parseInt(records) &&
      temp?.length < 5;
      i++
    ) {
      data?.docs[i]?.data()?.name !== "" &&
        temp.push({
          id: data?.docs[i]?.id,
          ...data?.docs[i]?.data(),
        });
    }
    dispatch({
      type: "GET_LEAST_VIEWED_PRODUCTS_BY_ID",
      payload: temp,
    });
    dispatch(Loader(false));
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);

    dispatch(Loader(false));
  }
};
export const getBestSellersById = (id) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const temp = [];
    const data = await firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topPerformingProducts")
      .where("restaurantID", "==", id)
      .orderBy("orders", "desc")
      .get();
    let records =
      data?.docs?.length > 1 ? data?.docs?.length / 2 : data?.docs?.length;
    for (
      let i = 0;
      i < data?.docs?.length &&
      temp?.length < parseInt(records) &&
      temp?.length < 5;
      i++
    ) {
      data?.docs[i]?.data()?.name !== "" &&
        temp.push({
          id: data?.docs[i]?.id,
          ...data?.docs[i]?.data(),
        });
    }
    dispatch({
      type: "GET_BEST_SELLERS_BY_ID",
      payload: temp,
    });
    dispatch(Loader(false));
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);

    dispatch(Loader(false));
  }
};
export const getWorstSellersById = (id) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const temp = [];
    const data = await firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topPerformingProducts")
      .where("restaurantID", "==", id)
      .orderBy("orders", "asc")
      .get();
    let records =
      data?.docs?.length > 1 ? data?.docs?.length / 2 : data?.docs?.length;
    for (
      let i = 0;
      i < data?.docs?.length &&
      temp?.length < parseInt(records) &&
      temp?.length < 5;
      i++
    ) {
      data?.docs[i]?.data()?.name !== "" &&
        temp.push({
          id: data?.docs[i]?.id,
          ...data?.docs[i]?.data(),
        });
    }
    dispatch({
      type: "GET_WORST_SELLERS_BY_ID",
      payload: temp,
    });
    dispatch(Loader(false));
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);

    dispatch(Loader(false));
  }
};

export const getTopMerchants = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topMerchants")
      .orderBy("orders", "desc")
      .get()
      .then((data) => {
        let temp = [];
        for (let i = 0; i < data?.docs?.length && temp?.length < 5; i++) {
          data?.docs[i]?.data()?.name !== "" &&
            temp.push({
              id: data?.docs[i]?.id,
              ...data?.docs[i]?.data(),
            });
        }
        dispatch({
          type: "GET_TOP_MERCHANTS",
          payload: temp,
        });
        dispatch(Loader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err.message);
        dispatch(Loader(false));
      });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
    dispatch(Loader(false));
  }
};

export const getTopProducts = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("topPerformingProducts")
      .orderBy("orders", "desc")
      .get()
      .then((data) => {
        let temp = [];
        for (let i = 0; i < data?.docs?.length && temp?.length < 5; i++) {
          data?.docs[i]?.data()?.name !== "" &&
            temp.push({
              id: data?.docs[i]?.id,
              ...data?.docs[i]?.data(),
            });
        }
        dispatch({
          type: "GET_TOP_PRODUCTS",
          payload: temp,
        });
        dispatch(Loader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err.message);
        dispatch(Loader(false));
      });
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(Loader(false));
  }
};

export const getOrderAnalysis = () => async (dispatch) => {
  dispatch(Loader(true));
  try {
    firebase
      .firestore()
      .collection("stats")
      .doc("V2c3nbW2GstL7zYV9SYS")
      .collection("orderAnalysis")
      .get()
      .then((data) => {
        let temp = [];
        for (let i = 0; i < data?.docs?.length; i++) {
          temp.push({
            id: data?.docs[i]?.id,
            ...data?.docs[i]?.data(),
          });
        }
        console.log(temp, "tempdata");
        dispatch({
          type: "GET_ORDER_ANALYSIS",
          payload: temp,
        });
        dispatch(Loader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err.message);
        dispatch(Loader(false));
      });
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(Loader(false));
  }
};

export const getProducts = (payload) => async (dispatch) => {
  dispatch(productLoader(true));
  try {
    const { data } = await Stats.getProducts(payload);
    if (data.success) {
      dispatch({
        type: "PRODUCTS_DATA",
        payload: data?.data,
      });
      dispatch(productLoader(false));
    } else {
      toast.error(data.message);
      console.log(data.message);
      dispatch(productLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(productLoader(false));
  }
};

export const getResturantRevenue = (id) => async (dispatch) => {
  dispatch(reveueLoader(true));
  try {
    const { data } = await Stats.getRevenue(id);
    if (data.success) {
      dispatch({
        type: "RESTAURANT_REVENUE",
        payload: data?.data,
      });
      dispatch(reveueLoader(false));
    } else {
      toast.error(data.message);
      console.log(data.message);
      dispatch(reveueLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(reveueLoader(false));
  }
};

export const getOrderAnalysisById = (id) => async (dispatch) => {
  console.log(id, "IDDDDDD");
  dispatch(restaurantAnalysisLoader(true));
  try {
    const { data } = await Stats.getAnalysisByID(id);
    console.log(data, "getAnalysisById>>>>>>>>");
    if (data.success) {
      dispatch({
        type: "RESTAURANT_ORDER_ANALYSIS",
        payload: data?.data,
      });
      dispatch(restaurantAnalysisLoader(false));
    } else {
      toast.error(data.message);
      console.log(data.message);
      dispatch(restaurantAnalysisLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    console.log(error.message);
    dispatch(restaurantAnalysisLoader(false));
  }
};

export const getRestaurantVisitsById = (id) => async (dispatch) => {
  dispatch(visitsLoader(true));
  try {
    const { data } = await Stats.getRestaurantVisits(id);
    if (data.success) {
      dispatch({
        type: "RESTAURANT_VISITS",
        payload: data?.data,
      });
      dispatch(visitsLoader(false));
    } else {
      toast.error(data.message);
      dispatch(visitsLoader(false));
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(visitsLoader(false));
  }
};

export const getFilteredVisits = (payload) => async (dispatch) => {
  if (!payload.startDate || !payload.endDate) {
    console.log("Start date or end date is empty");
    return; // Exit the function early
  }
  const startDate = new Date(payload?.startDate);
  const endDate = new Date(payload?.endDate);
  console.log(startDate, endDate, "start and end dates in action>>>>>");
  // Convert timestamps to Firestore timestamp format
  const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate);
  const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate);
  let visit = 0;
  firebase
    .firestore()
    .collection("visits")
    .where("restaurantID", "==", payload?.restaurantID)
    .where("createdAt", ">=", startTimestamp)
    .where("createdAt", "<=", endTimestamp)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc) {
          visit++;
        }
      });

      const timeDifferenceInMilliseconds =
        endDate.getTime() - startDate.getTime();

      const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
      const timeDifferenceInDays =
        timeDifferenceInMilliseconds / oneDayInMilliseconds;

      let finalResult = (visit / timeDifferenceInDays).toFixed(2);

      dispatch({
        type: "DAILY_VISITS",
        payload: finalResult,
      });
    })
    .catch((error) => {
      console.error("Error getting documents: ", error);
    });
};
const peakHoursLoader = (data) => async (dispatch) => {
  dispatch({
    type: "Peak_Hours_LOADER",
    payload: data,
  });
};
const restaurantAnalysisLoader = (data) => async (dispatch) => {
  dispatch({
    type: "RESTAURANT_ANALYSIS_LOADER",
    payload: data,
  });
};
const reveueLoader = (data) => async (dispatch) => {
  dispatch({
    type: "REVENUE_LOADER",
    payload: data,
  });
};
const visitsLoader = (data) => async (dispatch) => {
  dispatch({
    type: "VISITS_LOADER",
    payload: data,
  });
};
const customerLoader = (data) => async (dispatch) => {
  dispatch({
    type: "customer_LOADER",
    payload: data,
  });
};
const paymentLoader = (data) => async (dispatch) => {
  dispatch({
    type: "PAYMENT_LOADER",
    payload: data,
  });
};
const frequencyLoader = (data) => async (dispatch) => {
  dispatch({
    type: "frequency_LOADER",
    payload: data,
  });
};
const productLoader = (data) => async (dispatch) => {
  dispatch({
    type: "product_LOADER",
    payload: data,
  });
};
const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "STATS_LOADER",
    payload: data,
  });
};
