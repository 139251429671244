import OnlyHeader from "components/Headers/OnlyHeader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LabelsMenu from "components/CreateNewMenu/LabelsMenu";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "reactstrap";
import {
  addParentMenu,
  addlabel,
  deleteMenuNew,
  getAllParentMenu,
  getParentMenu,
  updateParentMenu,
} from "store/actions/MenuManagmentActions";

import EditParentMenu from "components/CreateNewMenu/EditParentMenu";
import SwitchToggler from "components/SwitchToggler";
import exportFromJSON from "export-from-json";
import {
  getMenu,
  getMenuCategoriesAndItems,
  getParentMenuData,
} from "store/actions/MenuManagmentActions";
import settings from "../assets/img/icons/Settings.svg";

const CreateNewMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { user } = useSelector((state) => state.auth);
  const { user, userPermissions } = useSelector((state) => state.auth);

  const menuPermissions = userPermissions?.menus;
  const {
    parentMenus,
    addMenuLoader,
    editLabelLoader,
    addLabelLoader,
    importLoader,
    deleteLoader,
  } = useSelector((state) => state.menu);

  // const [isOpen, setIsOpen] = useState(false);
  const [isMenuNameOpen, setIsMenuNameOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState("");
  // const [selectedOption, setSelectedOption] = useState(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [menuID, setMenuID] = useState("");
  const [selectedTab, setSelectedTab] = useState("Menu Details");
  const [labelName, setLabelName] = useState("");
  const [labelDescription, setLabelDescription] = useState("");
  const [labelModal, setLabelModal] = useState(false);
  const [parentMenusData, setParentMenusData] = useState([
    // Initial menu items
  ]);
  const [menuSearch, setMenuSearch] = useState();
  const [offcanvas, setOffcanvas] = useState(false);
  const [editParentMenu, setEditParentMenu] = useState({});

  useEffect(() => {
    setParentMenusData(parentMenus);
  }, [parentMenus]);

  const labeltoggle = () => {
    setLabelModal(!labelModal);
  };

  // let menuCreationOption = [
  //   // {
  //   //   icon: <i class="fas fa-2x fa-file-import"></i>,
  //   //   title: "Import your menu",
  //   //   subtitle: "Download the sample sheet(CSV) and fill with your items.",
  //   // },
  //   {
  //     icon: <i class="fas fa-2x fa-cubes"></i>,
  //     title: "Start from scratch",
  //     subtitle: "Start with an empty menu.",
  //   },
  //   // {
  //   //   icon: <i class="fas fa-2x fa-plus"></i>,
  //   //   title: "Create a sample menu",
  //   //   subtitle: " Start with a pre-builded menu.",
  //   // },
  // ];

  // const fileRef = useRef();
  // const handleClickMenu = () => {
  //   fileRef.current.click();
  // };

  const exportData = async (ele) => {
    setExportLoader(true);
    try {
      const menu = await new Promise((resolve) => {
        dispatch(getParentMenuData(ele.id, resolve));
      });
      if (menu) {
        const categoriesAndItems = await new Promise((resolve) => {
          dispatch(getMenuCategoriesAndItems(ele.id, resolve));
        });

        const csvData = [];

        categoriesAndItems?.forEach((Category) => {
          for (let items of Category.items) {
            let sizesArray = [];
            for (let size of items.sizes) {
              sizesArray.push(`${size.subItem}_${size.price}_${size.calories}`);
            }
            let sizesString = sizesArray.join(",");

            csvData.push({
              category: Category?.categoryName,
              MenuID: items?.menuID,
              itemName: items?.name,
              estTime: items?.estimatedTime,
              Size_price_calories: sizesString,
            });
          }
        });

        const fileName = menu?.name;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: csvData, fileName, exportType });
        setExportLoader(false);
      }
    } catch (error) {
      setExportLoader(false);
      console.error("Error exporting data:", error);
    }
  };

  const handleDelete = (ele) => {
    dispatch(
      getParentMenu(ele.id, (menu) => {
        const categoryIds = menu.map((category) => category.id);
        let allImages = [];

        menu.forEach((category) => {
          if (category.imageURL) {
            allImages.push(category.imageURL);
          }
          if (category.items && category.items.length > 0) {
            category.items.forEach((item) => {
              if (item.images && item.images.length > 0) {
                allImages = [...allImages, ...item.images];
              }
            });
          }
        });
        dispatch(deleteMenuNew(ele.id, categoryIds));
      })
    );
  };
  const handleParentMenu = (event) => {
    event.preventDefault();

    const alreadyExist = parentMenusData.find((item) => item.name == menuName);
    if (alreadyExist) {
      toast.error("Menu Already Added..!");
    } else {
      dispatch(
        addParentMenu(menuName, user?.restaurantID, (id) => {
          history.push(`/admin/edit-menu/${id}`);
          setMenuID(id);
          setIsMenuNameOpen(false);
          // setIsOpen(false);
          setSelectedTab("");
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getAllParentMenu(user?.restaurantID));
  }, []);
  const { restaurantMedia } = useSelector((state) => state.restaurant);

  const handleSearchData = (e) => {
    const data = parentMenus.filter((obj) => obj.name?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
    setParentMenusData(data);
    setMenuSearch(e.target.value);
  };
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Reorder the parentMenusData array based on the new positions
    const items = Array.from(parentMenusData);
    const [reorderedItem] = items?.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    // Update the position property for each menu item
    const updatedItems = items?.map((item, index) => {
      return {
        ...item,
        position: index, // Assuming position starts from 1
      };
    });

    setParentMenusData(updatedItems);
    dispatch(updateParentMenu(updatedItems));
    // Update the state with the new order
  };

  useEffect(() => {
    dispatch(getMenu(user?.restaurantID));
  }, []);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      {user?.type === "kitchen-admin" ? (
        menuPermissions.get ? (
          <>
            <Container className="mt--7 vh-100 " fluid>
              <Row>
                <Col className=" shadow-sm bg-white pt-3">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    {menuID ? (
                      <i
                        className="fas fa-2x fa-angle-left cursor-pointer"
                        onClick={() => {
                          setMenuID("");
                          setSelectedTab("Menu Details");
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                    <h2 className="mb-0 ml-3">
                      {menuID ? menuName : "New Menu"}
                    </h2>
                    {!menuID && selectedTab == "Menu Details" && (
                      <Row>
                        <Input
                          placeholder="Search here..."
                          value={menuSearch}
                          onChange={(e) => {
                            handleSearchData(e);
                          }}
                        />
                      </Row>
                    )}

                    <div className="d-flex align-items-center ">
                      {menuPermissions?.add ? (
                        <>
                          <Button
                            disabled={addMenuLoader}
                            className="ml-3 bg-primary text-white  border-primary"
                            onClick={() => {
                              // setIsOpen(true);
                              setMenuName("");
                              setIsMenuNameOpen(true);
                            }}
                          >
                            Create New Menu
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {menuID ? (
                    ""
                  ) : (
                    <Nav pills>
                      <NavItem
                        className={`cursor-pointer mx-3 ${
                          selectedTab == "Menu Details"
                            ? `text-primary border-bottom border-primary`
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedTab("Menu Details");
                        }}
                      >
                        <h3
                          className={`${
                            selectedTab == "Menu Details"
                              ? "text-primary"
                              : restaurantMedia.adminColor
                          }`}
                        >
                          Menu Details
                        </h3>
                      </NavItem>

                      <NavItem
                        className={`cursor-pointer mx-3 ${
                          selectedTab == "Labels"
                            ? "text-primary border-bottom border-primary"
                            : ""
                        } text-center`}
                        onClick={() => {
                          setSelectedTab("Labels");
                        }}
                      >
                        <h3
                          className={`${
                            selectedTab == "Labels" ? "text-primary" : ""
                          }`}
                        >
                          Labels{" "}
                        </h3>
                      </NavItem>
                    </Nav>
                  )}
                </Col>
              </Row>

              {/* {menuID && <ScratchMenu menuID={menuID} />} */}
              {/* Menus Details Section */}
              {selectedTab === "Menu Details" && (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Row>
                    <Col className={offcanvas ? "col-8" : "Col-12"}>
                      {parentMenusData.length > 0 ? (
                        <Droppable droppableId="parentMenus">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {parentMenusData?.map((ele, index) => (
                                <Draggable
                                  key={ele.id}
                                  draggableId={ele.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      key={ele.id}
                                      className="d-flex align-items-center justify-content-between mt-2 bg-white rounded p-3 shadow"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center h-100">
                                          <i className="fas fa-2x fa-ellipsis-v"></i>
                                          <i className="fas fa-2x fa-ellipsis-v"></i>
                                        </div>
                                        <div className="ml-3 h-100 d-flex flex-column justify-content-space-evenly">
                                          <h2 className="mb-0 mt-2">
                                            {ele.name}
                                          </h2>
                                          <p className="text-muted">
                                            {moment
                                              .unix(ele.createdAt?.seconds)
                                              .format("MMM DD, YYYY")}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-center align-items-center">
                                        {menuPermissions?.delete ? (
                                          <>
                                            <div className="mx-2">
                                              <SwitchToggler
                                                activeValue={
                                                  ele?.isVisible == true ? 1 : 0
                                                }
                                                id={ele.id}
                                                collection={"parentMenus"}
                                              />
                                            </div>
                                            <Button
                                              className="bg-danger text-white border-danger mr-2 btn-sm"
                                              disabled={
                                                deleteLoader &&
                                                ele.id == selectedEdit
                                              }
                                              onClick={(event) => {
                                                setSelectedEdit(ele.id);
                                                handleDelete(ele);
                                              }}
                                            >
                                              {deleteLoader &&
                                                ele.id == selectedEdit && (
                                                  <Spinner
                                                    size={"sm"}
                                                    className="mr-2"
                                                  ></Spinner>
                                                )}{" "}
                                              Delete
                                            </Button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {menuPermissions?.export ? (
                                          <>
                                            {" "}
                                            <Button
                                              className="bg-primary text-white border-primary mr-2 btn-sm"
                                              disabled={
                                                importLoader &&
                                                ele.id == selectedEdit
                                              }
                                              onClick={(event) => {
                                                setSelectedEdit(ele.id);
                                                exportData(ele);
                                              }}
                                            >
                                              {exportLoader &&
                                                ele.id == selectedEdit && (
                                                  <Spinner
                                                    size={"sm"}
                                                    className="mr-2"
                                                  ></Spinner>
                                                )}{" "}
                                              Export
                                            </Button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {menuPermissions?.edit ? (
                                          <>
                                            <Button
                                              className="bg-primary text-white border-primary btn-sm"
                                              disabled={editLabelLoader}
                                              onClick={(event) => {
                                                setSelectedEdit(ele.id);
                                                localStorage.setItem(
                                                  "menuID",
                                                  ele.id
                                                );
                                                dispatch(
                                                  getParentMenu(ele.id, () => {
                                                    history.push(
                                                      `/admin/edit-menu/${ele.id}`
                                                    );
                                                  })
                                                );
                                              }}
                                            >
                                              {editLabelLoader &&
                                                ele.id == selectedEdit && (
                                                  <Spinner
                                                    size={"sm"}
                                                    className="mr-2"
                                                  ></Spinner>
                                                )}{" "}
                                              Edit Menu
                                            </Button>
                                            <img
                                              src={settings}
                                              onClick={() => {
                                                setOffcanvas(true);
                                                setEditParentMenu(ele);
                                                setMenuName(ele?.name);
                                              }}
                                              alt="settings icon"
                                              width={20}
                                              className="cursor-pointer"
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      ) : (
                        <div className="w-100 v-100 d-flex justify-content-center align-items-center py-5">
                          <strong>No Menus found.</strong>
                        </div>
                      )}
                    </Col>
                    {offcanvas && (
                      <Col className="col-4">
                        <EditParentMenu
                          editParentMenu={editParentMenu}
                          menuName={menuName}
                          setMenuName={setMenuName}
                          setOffcanvas={setOffcanvas}
                        />
                      </Col>
                    )}
                  </Row>
                </DragDropContext>
              )}
              {/* Label Section */}
              {selectedTab == "Labels" && <LabelsMenu></LabelsMenu>}
            </Container>{" "}
          </>
        ) : (
          <Row className="py-4 justify-content-center align-items-center">
            You don't have the permission to access the page
          </Row>
        )
      ) : (
        <Container className="mt--7 vh-100 " fluid>
          <Row>
            <Col className=" shadow-sm bg-white pt-3">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {menuID ? (
                  <i
                    className="fas fa-2x fa-angle-left cursor-pointer"
                    onClick={() => {
                      setMenuID("");
                      setSelectedTab("Menu Details");
                    }}
                  ></i>
                ) : (
                  ""
                )}
                <h2 className="mb-0 ml-3">{menuID ? menuName : "New Menu"}</h2>
                {!menuID && selectedTab == "Menu Details" && (
                  <Row>
                    <Input
                      placeholder="Search here..."
                      value={menuSearch}
                      onChange={(e) => {
                        handleSearchData(e);
                      }}
                    />
                  </Row>
                )}

                <div className="d-flex align-items-center ">
                  <Button
                    disabled={addMenuLoader}
                    className="ml-3 bg-primary text-white  border-primary"
                    onClick={() => {
                      // setIsOpen(true);
                      setMenuName("");
                      setIsMenuNameOpen(true);
                    }}
                  >
                    Create New Menu
                  </Button>
                </div>
              </div>
              {menuID ? (
                ""
              ) : (
                <Nav pills>
                  <NavItem
                    className={`cursor-pointer mx-3 ${
                      selectedTab == "Menu Details"
                        ? `text-primary border-bottom border-primary`
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedTab("Menu Details");
                    }}
                  >
                    <h3
                      className={`${
                        selectedTab == "Menu Details"
                          ? "text-primary"
                          : restaurantMedia.adminColor
                      }`}
                    >
                      Menu Details
                    </h3>
                  </NavItem>

                  <NavItem
                    className={`cursor-pointer mx-3 ${
                      selectedTab == "Labels"
                        ? "text-primary border-bottom border-primary"
                        : ""
                    } text-center`}
                    onClick={() => {
                      setSelectedTab("Labels");
                    }}
                  >
                    <h3
                      className={`${
                        selectedTab == "Labels" ? "text-primary" : ""
                      }`}
                    >
                      Labels{" "}
                    </h3>
                  </NavItem>
                </Nav>
              )}
            </Col>
          </Row>

          {/* {menuID && <ScratchMenu menuID={menuID} />} */}
          {/* Menus Details Section */}
          {selectedTab === "Menu Details" && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Row>
                <Col className={offcanvas ? "col-8" : "Col-12"}>
                  {parentMenusData.length > 0 ? (
                    <Droppable droppableId="parentMenus">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {parentMenusData?.map((ele, index) => (
                            <Draggable
                              key={ele.id}
                              draggableId={ele.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={ele.id}
                                  className="d-flex align-items-center justify-content-between mt-2 bg-white rounded p-3 shadow"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center h-100">
                                      <i className="fas fa-2x fa-ellipsis-v"></i>
                                      <i className="fas fa-2x fa-ellipsis-v"></i>
                                    </div>
                                    <div className="ml-3 h-100 d-flex flex-column justify-content-space-evenly">
                                      <h2 className="mb-0 mt-2">{ele.name}</h2>
                                      <p className="text-muted">
                                        {moment
                                          .unix(ele.createdAt?.seconds)
                                          .format("MMM DD, YYYY")}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center align-items-center">
                                    <div className="mx-2">
                                      <SwitchToggler
                                        activeValue={
                                          ele?.isVisible == true ? 1 : 0
                                        }
                                        id={ele.id}
                                        collection={"parentMenus"}
                                      />
                                    </div>
                                    <Button
                                      className="bg-danger text-white border-danger mr-2 btn-sm"
                                      disabled={
                                        deleteLoader && ele.id == selectedEdit
                                      }
                                      onClick={(event) => {
                                        setSelectedEdit(ele.id);
                                        handleDelete(ele);
                                      }}
                                    >
                                      {deleteLoader &&
                                        ele.id == selectedEdit && (
                                          <Spinner
                                            size={"sm"}
                                            className="mr-2"
                                          ></Spinner>
                                        )}{" "}
                                      Delete
                                    </Button>
                                    <Button
                                      className="bg-primary text-white border-primary mr-2 btn-sm"
                                      disabled={
                                        importLoader && ele.id == selectedEdit
                                      }
                                      onClick={(event) => {
                                        setSelectedEdit(ele.id);
                                        exportData(ele);
                                      }}
                                    >
                                      {exportLoader &&
                                        ele.id == selectedEdit && (
                                          <Spinner
                                            size={"sm"}
                                            className="mr-2"
                                          ></Spinner>
                                        )}{" "}
                                      Export
                                    </Button>
                                    <Button
                                      className="bg-primary text-white border-primary btn-sm"
                                      disabled={editLabelLoader}
                                      onClick={(event) => {
                                        setSelectedEdit(ele.id);
                                        localStorage.setItem("menuID", ele.id);
                                        dispatch(
                                          getParentMenu(ele.id, () => {
                                            history.push(
                                              `/admin/edit-menu/${ele.id}`
                                            );
                                          })
                                        );
                                      }}
                                    >
                                      {editLabelLoader &&
                                        ele.id == selectedEdit && (
                                          <Spinner
                                            size={"sm"}
                                            className="mr-2"
                                          ></Spinner>
                                        )}{" "}
                                      Edit Menu
                                    </Button>
                                    <img
                                      src={settings}
                                      onClick={() => {
                                        setOffcanvas(true);
                                        setEditParentMenu(ele);
                                        setMenuName(ele?.name);
                                      }}
                                      width={20}
                                      className="cursor-pointer"
                                      alt="settings icon"
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ) : (
                    <div className="w-100 v-100 d-flex justify-content-center align-items-center py-5">
                      <strong>No Menus found.</strong>
                    </div>
                  )}
                </Col>
                {offcanvas && (
                  <Col className="col-4">
                    <EditParentMenu
                      editParentMenu={editParentMenu}
                      menuName={menuName}
                      setMenuName={setMenuName}
                      setOffcanvas={setOffcanvas}
                    />
                  </Col>
                )}
              </Row>
            </DragDropContext>
          )}
          {selectedTab == "Labels" && <LabelsMenu></LabelsMenu>}
        </Container>
      )}

      <Modal
        isOpen={isMenuNameOpen}
        toggle={() => setIsMenuNameOpen(false)}
        centered
      >
        <i
          className="fa fa-1x fa-close ml-auto mr-3 mt-3 "
          onClick={() => {
            setIsMenuNameOpen(false);
          }}
        ></i>

        <ModalBody>
          <Form onSubmit={handleParentMenu}>
            <FormGroup>
              <Label for="name">
                <h2>Menu Name</h2>
              </Label>
              <Input
                id="name"
                name="menuName"
                placeholder="Fast Food"
                type="text"
                required
                value={menuName}
                onChange={(e) => setMenuName(e.target.value)}
              />
            </FormGroup>
            <Button
              color="primary float-right"
              type="submit"
              disabled={addMenuLoader}
              className="d-flex align-items-center"
            >
              {addMenuLoader ? (
                <Spinner size={"sm"} className="mr-2"></Spinner>
              ) : (
                ""
              )}{" "}
              Save
            </Button>
          </Form>
          {/* </div> */}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {/* Modal for add restaurant */}
      <div>
        <Modal isOpen={labelModal} toggle={labeltoggle}>
          <ModalHeader toggle={labeltoggle}>Add Label Details</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  addlabel(
                    {
                      labelName,
                      labelDescription,
                      restaurantID: user?.restaurantID,
                    },
                    () => {
                      setLabelDescription("");
                      setLabelName("");
                      setLabelModal(false);
                    }
                  )
                );
              }}
            >
              <FormGroup>
                <Label for="name">Label Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Enter name"
                  value={labelName}
                  onChange={(e) => setLabelName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="location">Description</Label>
                <Input
                  type="textarea"
                  placeholder="Enter Description"
                  required
                  value={labelDescription}
                  onChange={(e) => setLabelDescription(e.target.value)}
                />
              </FormGroup>

              <div className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {addLabelLoader ? <Spinner size="sm" /> : "Save"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default CreateNewMenu;
