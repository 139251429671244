/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Spinner,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { toast } from "react-toastify";
import { addNewUser } from "store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteDriver } from "store/actions/driverAction";
import { getAllDrivers } from "store/actions/driverAction";
import { updateDriver } from "store/actions/driverAction";

const Drivers = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.catalog);
  const { isLoading, driverData, driverRestData } = useSelector(
    (state) => state.driver
  );
  const { uid } = useSelector((state) => state.auth);
  const [searchField, setSearchField] = useState("");
  const [hitsPerPage, setHitsPerPage] = useState(5);
  const [id, setId] = useState("");
  const [permissionId, setPermissionId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [currentPage, setCurrentPage] = useState(0);

  const [menu, setMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState({
    get: true,
    add: false,
    import: false,
    export: false,
    edit: false,
    delete: false,
  });

  const [category, setCategory] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState({
    get: true,
    add: false,
    edit: false,
    delete: false,
  });

  const [label, setLabel] = useState(false);
  const [labelOptions, setlabelOptions] = useState({
    get: true,
    add: false,
    edit: false,
    delete: false,
  });

  const [order, setOrder] = useState(false);
  const [orderOptions, setOrderOptions] = useState({
    get: true,
    update: true,
  });

  const [requests, setRequests] = useState(false);
  const [requestOptions, setRequestOptions] = useState({
    get: true,
  });

  const [reports, setReports] = useState(false);
  const [reportsOptions, setreportsOptions] = useState({
    get: true,
    export: false,
  });

  const [catalog, setCatalog] = useState(false);
  const [catalogOptions, setCatalogOptions] = useState({
    get: true,
    add: false,
    edit: false,
    delete: false,
  });

  const [checkoutQuestions, setCheckoutQuestions] = useState(false);
  const [checkoutOptions, setCheckoutOptions] = useState({
    get: true,
    add: false,
    update: false,
  });

  const [settings, setSettings] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState({
    get: true,
  });

  const [notifications, setNotifications] = useState(false);
  const [notificationsOptions, setNotificationsOptions] = useState({
    get: true,
  });

  const [customer, setCustomer] = useState(false);
  const [customerOptions, setCustomerOptions] = useState({
    get: true,
  });
  const [customization, setCustomization] = useState(false);
  const [customizationOptions, setCustomizationOptions] = useState({
    get: true,
    add: false,
    update: false,
  });

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    if (name === "role") {
      clearData();
    }
  };

  const addtoggle = () => {
    setAddModal(!addModal);
    clearFormData();
  };

  const [editModal, setEditModal] = useState(false);

  const editToggle = () => {
    setEditModal(!editModal);
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const deletetoggle = () => {
    setDeleteModal(!deleteModal);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = driverRestData?.nbPages;
    if (totalPages >= 1) {
      const visiblePages = 3; // or 4, depending on your preference
      let startPage = Math.max(currentPage - 1, 0);
      let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);
      if (startPage > 0) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="">...</PaginationLink>
          </PaginationItem>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink href="" onClick={() => handleClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (endPage < totalPages - 1) {
        if (endPage < totalPages - 2) {
          pageNumbers.push(
            <PaginationItem key="end-ellipsis" disabled>
              <PaginationLink href="">...</PaginationLink>
            </PaginationItem>
          );
        }

        pageNumbers.push(
          <PaginationItem key={totalPages - 1}>
            <PaginationLink href="" onClick={() => handleClick(totalPages - 1)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return pageNumbers;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      name: formData.name + " " + formData.lastName,
      email: formData.email,
      password: formData.password,
      type: "driver",
      isDeleted: false,
      isAssigned: false,
      restaurantID: uid,
    };
    let permissions = {};

    if (menu) {
      permissions.menus = menuOptions;
    }
    // if (category) {
    //   permissions.category = categoryOptions;
    // }
    if (label) {
      permissions.labels = labelOptions;
    }
    if (order) {
      permissions.order = orderOptions;
    }
    if (requests) {
      permissions.requests = requestOptions;
    }
    // if (reports) {
    //   permissions.reports = reportsOptions;
    // }
    if (catalog) {
      permissions.catalog = catalogOptions;
    }
    if (checkoutQuestions) {
      permissions.checkoutQuestions = checkoutOptions;
    }
    // if (settings) {
    //   permissions.settings = settingsOptions;
    // }
    // if (notifications) {
    //   permissions.notifications = notificationsOptions;
    // }
    if (customer) {
      permissions.customers = customerOptions;
    }
    if (customization) {
      permissions.customization = customizationOptions;
    }
    dispatch(
      addNewUser(obj, permissions, () => {
        toast.success("Driver Added SuccessFully");
        clearFormData();
        addtoggle();
        clearData();
        setTimeout(() => {
          dispatch(getAllDrivers(uid, searchField, hitsPerPage, currentPage));
        }, 2000);
      })
    );
  };

  const handleUpdateDriver = (event) => {
    event.preventDefault();
    let obj = {
      name: formData.name + " " + formData.lastName,
    };
    if (formData?.email) {
      obj = { ...obj, email: formData.email };
    }
    if (formData?.password) {
      obj = { ...obj, password: formData.password };
    }
    let permissions = {};

    if (menu) {
      permissions.menus = menuOptions;
    }
    // if (category) {
    //   permissions.category = categoryOptions;
    // }
    if (label) {
      permissions.labels = labelOptions;
    }
    if (order) {
      permissions.order = orderOptions;
    }
    if (requests) {
      permissions.requests = requestOptions;
    }
    // if (reports) {
    //   permissions.reports = reportsOptions;
    // }
    if (catalog) {
      permissions.catalog = catalogOptions;
    }
    if (checkoutQuestions) {
      permissions.checkoutQuestions = checkoutOptions;
    }
    // if (settings) {
    //   permissions.settings = settingsOptions;
    // }
    // if (notifications) {
    //   permissions.notifications = notificationsOptions;
    // }
    if (customer) {
      permissions.customers = customerOptions;
    }
    if (customization) {
      permissions.customization = customizationOptions;
    }
    dispatch(
      updateDriver(id, obj, () => {
        editToggle();
        clearData();
        toast.success("Driver Updated SuccessFully");
        clearFormData();
        setTimeout(() => {
          dispatch(getAllDrivers(uid, searchField, hitsPerPage, currentPage));
        }, 2000);
      })
    );
  };

  const clearData = () => {
    setMenu(false);
    setMenuOptions({
      get: true,
      add: false,
      import: false,
      export: false,
      edit: false,
      delete: false,
    });
    setCategory(false);
    setCategoryOptions({
      get: true,
      add: false,
      edit: false,
      delete: false,
    });
    setLabel(false);
    setlabelOptions({
      get: true,
      add: false,
      edit: false,
      delete: false,
    });
    setOrder(false);
    setOrderOptions({
      get: true,
      update: true,
    });

    setRequests(false);
    setRequestOptions({
      get: true,
    });

    setReports(false);
    setRequestOptions({
      get: true,
      export: false,
    });

    setNotifications(false);
    setNotificationsOptions({
      get: true,
    });
    setCatalog(false);
    setCatalogOptions({
      get: true,
      add: false,
      edit: false,
      delete: false,
    });
    // setSettings(false);
    // setSettingsOptions({
    //   get: true,
    // });
    setCheckoutQuestions(false);
    setCheckoutOptions({
      get: true,
      add: false,
      update: false,
    });
    setCustomer(false);
    setCustomerOptions({
      get: true,
    });
    setCustomization(false);
    setCustomerOptions({
      get: true,
      add: false,
      update: false,
    });
  };

  const clearFormData = () => {
    setFormData({
      name: "",
      lastName: "",
      email: "",
      password: "",
    });
  };
  const HandleDeleteBtn = () => {
    dispatch(
      deleteDriver(id, () => {
        setTimeout(() => {
          dispatch(getAllDrivers(uid, searchField, hitsPerPage, currentPage));
        }, 2000);
      }),
      deletetoggle()
    );
  };

  useEffect(() => {
    dispatch(getAllDrivers(uid, searchField, hitsPerPage, currentPage));
  }, [searchField, hitsPerPage, currentPage]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7 mb-5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="d-lg-flex  d-sm-block justify-content-between">
                <h3 className=" pt-2 col-lg-2 ">Drivers</h3>
                <Row>
                  <Col xs="12" md="4">
                    {" "}
                    <Input
                      placeholder="Search here..."
                      onChange={(e) => setSearchField(e.target.value)}
                      value={searchField}
                    />
                  </Col>
                  <Col xs="12" md="4" className="my-2 my-md-0">
                    {" "}
                    <Input
                      type="select"
                      id="exampleCustomSelect"
                      name="customSelect"
                      value={hitsPerPage}
                      onChange={(e) => {
                        setHitsPerPage(e.target.value);
                        setCurrentPage(0);
                      }}
                    >
                      <option value="5">Show Entries</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </Input>
                  </Col>
                  <Col xs="12" md="4">
                    {" "}
                    <Button color="primary" onClick={addtoggle} block>
                      Add
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                {driverData?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan="4">
                        {/* <Spinner /> */}
                        No Drivers to display
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">email</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {driverData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.name.split(" ")[0]}</td>
                            <td>{item?.name.split(" ")[1]}</td>
                            <td>{item?.email}</td>

                            <td>
                              <Button
                                className="btn-sm"
                                color="primary"
                                onClick={() => {
                                  setId(item?.id);
                                  setFormData({
                                    name: item?.name.split(" ")[0],
                                    lastName: item?.name.split(" ")[1],
                                    email: "",
                                    password: "",
                                  });
                                  editToggle();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                className="btn-sm"
                                color="danger"
                                onClick={() => {
                                  setId(item?.id);
                                  setPermissionId(item?.permissions?.id);
                                  deletetoggle();
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage === 0}>
                      <PaginationLink
                        href=""
                        onClick={() => handleClick(currentPage - 1)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {/* Page numbers */}
                    {renderPageNumbers()}
                    {/* Next button */}
                    <PaginationItem
                      disabled={currentPage === driverRestData?.nbPages - 1}
                    >
                      <PaginationLink
                        href=""
                        onClick={() => handleClick(currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>

        {/* Modal for add restaurant */}
        <div>
          <Modal isOpen={addModal} toggle={addtoggle}>
            <ModalHeader toggle={addtoggle}>Add Driver</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="name">First Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    required
                    placeholder="First Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="location">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="last-name"
                    required
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    required
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    required
                    id="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button color="primary" disabled={loading}>
                    {loading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>

        {/* edit modal  */}
        <div>
          <Modal isOpen={editModal} toggle={editToggle}>
            <ModalHeader toggle={editToggle}>Edit Driver</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleUpdateDriver}>
                <FormGroup>
                  <Label for="name">First Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="First Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="location">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="last-name"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button color="primary" disabled={isLoading}>
                    {isLoading ? <Spinner size="sm" /> : "Save"}
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          {/* Delete Modal */}
          <Modal isOpen={deleteModal}>
            <ModalHeader toggle={deletetoggle}>Delete Driver</ModalHeader>
            <ModalBody>
              <h3>Are you sure you want to delete ?</h3>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={HandleDeleteBtn}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner size="sm" /> : "Delete"}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default Drivers;
