
import React, { useState } from "react";
import { postContact } from "../../store/actions/contactActions";
import { useSelector,useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
function ContactUs() {
  const dispatch=useDispatch();
  const {loading}=useSelector(state=>state.contacts);
  const [name,setName]=useState('');
  const [email,setEmail]=useState(' ');
  const [message,setMessage]=useState('');
  return (
    <>
<div class="container-fluid d-flex align-items-center justify-content-center min-vh-100 bg-light mt-5 main-content-auth">
  <div class="w-50 p-4 bg-white shadow-lg">
    <form  onSubmit={async(e)=>{
      e.preventDefault();
      if(!name || !email ||!message){
        toast.error("Please fill all required fields!");
      }
      await dispatch(postContact(name,email,message));
      setEmail("");
      setName("");
      setMessage("");
    }}>
      <h1 class="h4 mb-4 text-left">Get in Touch</h1>

      <div class="form-group position-relative mb-3">
        <label htmlFor="name" class="sr-only">Name</label>
        <input type="text" class="form-control" id="name" name="name" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)} required/>
      </div>

      <div class="form-group position-relative mb-3">
        <label htmlFor="email" class="sr-only">Email</label>
        <input type="email" class="form-control" id="email" name="email" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
      </div>

      <div class="form-group position-relative mb-3">
        <label htmlFor="message" class="sr-only">Message</label>
        <textarea class="form-control" id="message" name="message" rows="5" placeholder="Your message..." value={message} onChange={(e)=>setMessage(e.target.value)} required></textarea>
      </div>

      <div class="d-flex justify-content-center mt-4">
        <button type="submit" class="btn btn-danger px-5">
          {loading? <Spinner size="sm" color="light"/>:"Contact Us"}
          </button>
      </div>
    </form>
  </div>
</div>
    </>
  );
}

export default ContactUs;
