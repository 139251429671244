import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Button,
  Input,Tooltip
} from "reactstrap";
import fbvector from "../../assets/img/socialIcons/fbVector.svg";
import insta from "../../assets/img/socialIcons/insta.svg";
import twiterVector from "../../assets/img/socialIcons/twiterVector.svg";
import snapchat from "../../assets/img/socialIcons/snapchat.svg";
import discord from "../../assets/img/socialIcons/discord.svg";
import linkedIn from "../../assets/img/socialIcons/linkedinVector.svg";
import website from "../../assets/img/socialIcons/website.svg";
import linktree from "../../assets/img/socialIcons/linktree.svg";
import youtube from "../../assets/img/socialIcons/youtube.svg";
import tiktok from "../../assets/img/socialIcons/tiktok.svg";

import { useDispatch, useSelector } from "react-redux";
import { updateSocialProfile } from "store/actions/settingAction";

const SocialProfileTable = ({
  data,
  addToggle,
  editToggle,
  deleteToggle,
  setEditData,
  setIndex,props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const dispatch = useDispatch();
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const { user } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [url, setUrl] = useState("");
  const [Edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [urlError,setUrlError]=useState('');
  const validateURL=()=>{
   if(/^https?:\/\/(www\.)?(facebook|twitter|instagram|tiktok|linkedin|google|youtube|twitter|discord|snapchat|linktree)\.com\/[A-Za-z0-9_.-]+\/?$/.test(url))
{
  setUrlError('');
  return true;
}
else {
  setUrl('');
  setUrlError('Please enter valid url...');
  return false;
}
  }

  const numPages = Math.ceil(data?.length / pageSize);

  const pageLinks = [];

  for (let i = 1; i <= numPages; i++) {
    pageLinks.push(
      <PaginationItem key={i} active={i === currentPage}>
        <PaginationLink href="#pablo" onClick={() => setCurrentPage(i)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const pageData = data?.slice(startIndex, endIndex);
  // Sample data
  let updatedData = [
    {
      type: "facebook",
      icon: fbvector,
    },
    {
      type: "instagram",
      icon: insta,
    },
    {
      type: "tiktok",
      icon: tiktok,
    },
    {
      type: "discord",
      icon: discord,
    },
    {
      type: "youtube",
      icon: youtube,
    },
    {
      type: "website",
      icon: website,
    },
    {
      type: "linktree",
      icon: linktree,
    },
    {
      type: "linkedIn",
      icon: linkedIn,
    },
    {
      type: "snapchat",
      icon: snapchat,
    },
    {
      type: "twiter",
      icon: twiterVector,
    },
  ];

  const updatedSocialProfiles = data?.map((profile) => {
    const matchingIcon = updatedData.find((item) => item.type === profile.type);
    if (matchingIcon) {
      if (profile?.url !== "") {
        return {
          ...profile,
          icon: matchingIcon.icon,
        };
      } else {
        return {
          ...profile,
          icon: matchingIcon.icon,
          url: "",
        };
      }
    } else {
      return profile;
    }
  });

  const handleUpdate = (type, url) => {
    dispatch(updateSocialProfile(user?.restaurantID, type, url));
  };

  return (
    <>
      <Row className="py-4">
        <div className="col">
          {/* <Card className="shadow"> */}
          <div className="d-flex justify-content-between py-3">
            <div className="d-flex align-items-center">
              <h3 className=" pt-2 ">Social Profiles</h3>
            </div>

            {/* <Button color="primary" onClick={addToggle}>
              Add
            </Button> */}
          </div>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Type</th>
                <th scope="col">URL</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            {updatedSocialProfiles?.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td>
                      <div
                        className="d-flex justify-content-center align-items-center p-2"
                        style={{
                          background: `${
                            restaurantMedia?.adminColor || "#d70365"
                          }`,
                          width: "fit-content",
                          borderRadius: "100%",
                        }}
                      >
                        <img src={item?.icon} alt="Social Link" id={item?.type} className="img-fluid"/>
                      </div>
                    </td>

                    <td>
                      {index == editIndex ? (
                         <>
                        <Input
                          style={{
                            padding: ".5rem",
                            height: "auto",
                            width: "50%",
                          }}
                          type="text"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                       
                        {urlError ? 
                          <span className="text-danger" style={{ fontSize: "11px" }}>
                            {urlError}
                          </span>
                         : (
                          ""
                        )
                      }
                      </>
                      ) : (
                        item?.url
                      )}
                    </td>
                    <td>
                      {index == editIndex ? (
                        <>
                          <Button
                            size="sm"
                            color="success"
                            onClick={() => {
                              if (
                                (item?.url || url != undefined) &&
                                url !== ""
                              ) {
                                if(validateURL()){
                                handleUpdate(item.type, url);
                                setEditIndex(-1);
                                }else{
                                  return;
                                }
                              } else {
                                setEditIndex(-1);
                              }
                            }}
                          >
                            {item?.url ? 'Update':"Add"}
                          </Button>
                        </>
                      ) : (
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            setUrl(item.url);
                            // setEdit(true);
                            setEditIndex(index);
                          }}
                        >
                          {item.url? "Edit" : " Add"}
                        </Button>
                      )}
                    </td>
                  </tr>
                  <Tooltip placement="right" isOpen={tooltipOpen} target={item?.type} toggle={toggle}>
                {item.type}
              </Tooltip>
                </tbody>
              );
            })}
          </Table>
        </div>
      </Row>
    </>
  );
};

export default SocialProfileTable;



