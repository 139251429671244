
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Button,
  Input,
  Col,
} from "reactstrap";
import { updateCheckoutQuestion } from "store/actions/checkoutQuestionAction";
import DeleteQuestionModal from "../Modals/DeleteQuestionModal";
import EditCheckoutModal from "components/Modals/EditCheckoutModal";

function CheckoutQuestionTable({ data, addToggle, permissions }) {
  const { question } = useSelector((state) => state.checkoutQuestion);
  const { user, userPermissions } = useSelector((state) => state.auth);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [ID, setID] = useState("");
  const [editQuestion, setEditQuestion] = useState({
    title: "",
    type: "",
    isMandatory: false,
  });
  const checkOutPermissions = userPermissions?.checkoutQuestions;

  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setQuestions(question);
  }, [question]);

  const history = useHistory();

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Reorder the questions array based on the new positions
    const reorderedQuestions = Array.from(questions);
    const [removed] = reorderedQuestions.splice(source.index, 1);
    reorderedQuestions.splice(destination.index, 0, removed);

    // Update the position key based on the new order
    const updatedQuestions = reorderedQuestions.map((question, index) => ({
      ...question,
      position: index, // Assuming position starts from 1
    }));

    setQuestions(updatedQuestions);
    dispatch(updateCheckoutQuestion(updatedQuestions));
  };

  // Search functionality
  const [search, setSearch] = useState("");

  const handleSearchFilter = (e) => {
    setSearch(e.target.value);
  };

  const filteredQuestions = questions.filter((item) =>
    item.title.includes(search)
  );

  // Pagination coding and filtering
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const numPages = Math.ceil(filteredQuestions?.length / pageSize);

  const pageLinks = [];

  for (let i = 1; i <= numPages; i++) {
    pageLinks.push(
      <PaginationItem key={i} active={i === currentPage}>
        <PaginationLink href="#pablo" onClick={() => setCurrentPage(i)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const deleteToggle = () => setDeleteModal(!deleteModal);

  const editToggle = () => setEditModal(!editModal);

  return (
    <Row>
      <div className="col">
        <Card className="shadow">
          <CardHeader className="d-lg-flex justify-content-between">
            <h3>Customer Feedback</h3>
            <Row>
              <Col xs="12" sm="4" lg="4">
                <Input
                  placeholder="Search here..."
                  value={search}
                  onChange={handleSearchFilter}
                />
              </Col>
              <Col xs="12" sm="4" lg="4" className="my-2 my-sm-0">
                <Input
                  id="exampleSelect"
                  value={pageSize}
                  onChange={handlePageChange}
                  type="select"
                >
                  <option value="5">Show Entries</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Input>
              </Col>
              <Col xs="12" sm="4" lg="4" className="">
                {(user?.type === "restaurant" || checkOutPermissions?.add) && (
                  <Button color="primary" onClick={addToggle} block>
                    Add
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          {user?.type === "kitchen-admin" && !checkOutPermissions?.get ? (
            <Row className="py-4 justify-content-center align-items-center">
              You don't have permission to access this page.
            </Row>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <Droppable droppableId="questionList">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {filteredQuestions
                        .slice(startIndex, endIndex)
                        .map((question, index) => (
                          <Draggable
                            key={question.id}
                            draggableId={question.id}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                key={question.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <th scope="row">{question.title}</th>
                                <th scope="row">{question.type}</th>
                                <td>
                                  {(user?.type === "restaurant" ||
                                    checkOutPermissions?.update) && (
                                    <Button
                                      className="btn-sm"
                                      color="primary"
                                      onClick={() => {
                                        setEditQuestion(question);
                                        editToggle();
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}

                                  <Button
                                    className="btn-sm"
                                    color="success"
                                    onClick={() => {
                                      history.push(
                                        `/admin/checkout-responses/${question.id}`
                                      );
                                    }}
                                  >
                                    View Response
                                  </Button>
                                  {(user?.type === "restaurant" ||
                                    checkOutPermissions?.update) && (
                                    <Button
                                      className="btn-sm"
                                      color="danger"
                                      onClick={() => {
                                        setID(question.id);
                                        deleteToggle();
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
              <DeleteQuestionModal
                modal={deleteModal}
                toggle={deleteToggle}
                id={ID}
              />
              <EditCheckoutModal
                editToggle={editToggle}
                editModal={editModal}
                editQuestion={editQuestion}
                setEditQuestion={setEditQuestion}
              />
            </DragDropContext>
          )}

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    href="#pablo"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {pageLinks}
                <PaginationItem disabled={currentPage === numPages}>
                  <PaginationLink
                    href="#pablo"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </div>
    </Row>
  );
}

export default CheckoutQuestionTable;
