import firebase from "../../config/firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});

export const importMenuNew =
  (payload, restaurantID, onSuccess = () => {}, onError) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "IMPORT_LOADER",
        payload: true,
      });
      for (var item of payload) {
        const menuName = item?.MenuName;
        const categoryName = item?.CategoryName;

        if (!menuName || !categoryName) {
          toast.error("Invalid Data");
          dispatch({
            type: "IMPORT_LOADER",
            payload: false,
          });
          onError();
          break; // Skip processing this item and move to the next one
        }

        const querySnapshot = await firebase
          .firestore()
          .collection("parentMenus")
          .where("name", "==", menuName)
          .get();
        let menuRef;
        if (!querySnapshot.empty) {
          // Document with the matching menu name already exists
          menuRef = querySnapshot.docs[0].ref;
        } else {
          // Create a new document
          const newMenuData = {
            name: menuName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            restaurantID,
            position: 0, // Replace with the actual restaurant ID
          };

          const newMenuDoc = await firebase
            .firestore()
            .collection("parentMenus")
            .add(newMenuData);

          menuRef = newMenuDoc;
        }

        const categoryData = {
          categoryName,
          imageURL: "",
          menuID: menuRef ? menuRef.id : "", // Make sure menuRef is defined before accessing its ID
          restaurantID,
        };

        const category = await firebase
          .firestore()
          .collection("categories")
          .add(categoryData);

        const sizes =
          item?.Sizes &&
          item?.Sizes.split(",").map((ele) => {
            const [subItem, price] = ele.split("-");
            return { subItem, price };
          });

        const menuData = {
          name: item.ItemName,
          categoriesID: category.id,
          Calories: item.Calories,
          estimatedTime: item.EstTime,
          menuID: menuRef ? menuRef.id : "", // Make sure menuRef is defined before accessing its ID
          restaurantID,
          sizes,
          images: [],
          views: 0,
        };

        await firebase.firestore().collection("menus").add(menuData);
        dispatch({
          type: "IMPORT_LOADER",
          payload: false,
        });
        onSuccess();
        toast.success("Menus,Categorys and Items Added Successfully..!");
      }
    } catch (error) {
      toast.error("Error importing menu:", error);
      dispatch({
        type: "IMPORT_LOADER",
        payload: false,
      });
    }
  };

export const importMenu =
  (item, onSuccess = (res) => {}) =>
  async (dispatch) => {
    try {
      const docRef = await firebase.firestore().collection("menus").add(item);
      const menuDoc = await firebase
        .firestore()
        .collection("menus")
        .doc(docRef.id)
        .get();

      dispatch({
        type: "ADDED_MENU",
        payload: {
          id: docRef.id,
          ...menuDoc.data(),
        },
      });

      onSuccess({
        type: "item",
        id: docRef.id,
        ...menuDoc.data(),
      });
    } catch (error) {
      toast.error("Error Importing menu");
    }
  };

export const getMenu =
  (id, search, hitsPerPage, currentPage) => async (dispatch) => {
    firebase
      .firestore()
      .collection("menus")
      .where("restaurantID", "==", id)
      .onSnapshot((query) => {
        let menu = [];
        for (var doc of query.docs) {
          menu.push({ id: doc.id, ...doc.data() });
        }
        dispatch({
          type: "MENU",
          payload: menu,
        });
      });
  };

export const getMenuCategoriesAndItems =
  (ID, onSuccess = (categoriesAndItems) => {}) =>
  async (dispatch) => {
    await firebase
      .firestore()
      .collection("categories")
      .where("menuID", "==", ID)
      .onSnapshot(async (query) => {
        let categoies = [];
        for (let doc of query.docs) {
          if (doc.exists) {
            let Items = [];
            await firebase
              .firestore()
              .collection("menus")
              .where("menuID", "==", ID)
              .where("categoriesID", "==", doc.id)
              .get()
              .then((documents) => {
                for (let item of documents.docs) {
                  if (item.exists) {
                    Items.push({ id: item.id, ...item.data() });
                  }
                }
              });
            categoies.push({ id: doc.id, ...doc.data(), items: Items });
          }
        }
        onSuccess(categoies);
      });
  };

export const editMenu = (
  id,
  payload,
  onSuccess = () => {},
  imagesToRemove
) => async (dispatch) => {
  console.log(payload,"payload>>>>>>>>>>",id,"id>>>>>>>>>>>>");
  dispatch({
    type: "ADD_MENU_LOADER",
    payload: true,
  });

  // Remove images if any
  if (imagesToRemove && imagesToRemove.length > 0) {
    imagesToRemove.forEach((url) => {
      firebase.storage().refFromURL(url).delete();
    });
  }

  // Check if there are new images to upload
  let uploadImages = [];
  for (let image of payload.images) {
    if (image?.name) {
      let fileName = image.name;
      let fileName1 = fileName.slice(fileName.lastIndexOf("."));
      let fileName2 = uuidv4() + fileName1.toLowerCase();
      let storageRef = await firebase.storage().ref(fileName2).put(image);
      let url = await storageRef.ref.getDownloadURL();
      uploadImages.push(url);
    } else {
      uploadImages.push(image);
    }
  }

  // Create a new payload with only the fields that have changed
  let updatedPayload = {};
  if(payload.comments) updatedPayload.comments=payload.comments;
  if(payload.name) updatedPayload.name=payload.name;
  if(payload.recommendedProducts?.length>0) updatedPayload.recommendedProducts=payload.recommendedProducts;
  if(payload.menuID) updatedPayload.menuID=payload.menuID;
  if(payload.label?.length>0) updatedPayload.label=payload.label;
  if(payload.type) updatedPayload.type=payload.type;
  if(payload.restaurantID) updatedPayload.restaurantID=payload.restaurantID;
  if(payload.isVisible!==undefined) updatedPayload.isVisible=payload.isVisible;
  if(payload.isDeleted!==undefined) updatedPayload.isDeleted=payload.isDeleted;
  if(payload.id) updatedPayload.id=payload.id;
  if(payload.estimatedTime) updatedPayload.estimatedTime=payload.estimatedTime;
  if(payload.categoriesID) updatedPayload.categoriesID=payload.categoriesID;
  if (payload.labelName) updatedPayload.labelName = payload.labelName;
  if(payload.sizes?.length>0) updatedPayload.sizes=payload.sizes;
  if (payload.labelDescription) updatedPayload.labelDescription = payload.labelDescription;
  if (uploadImages?.length > 0) updatedPayload.images = uploadImages;
console.log(updatedPayload,"updatedpayload>>>>>>>>>>>>");
  // Update only if there are changes
  if (Object.keys(updatedPayload).length > 0) {
    await firebase
      .firestore()
      .collection("menus")
      .doc(id)
      .update(updatedPayload)
      .then(() => {
        onSuccess({
          type: "item",
          id: id,
          ...updatedPayload,
        });
        toast.success("Menu Updated");
        dispatch({
          type: "UPDATE_MENU",
          payload: {
            id: id,
            ...updatedPayload,
          },
        });
        dispatch({
          type: "ADD_MENU_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error updating menu");
        dispatch({
          type: "ADD_MENU_LOADER",
          payload: false,
        });
      });
  } else {
    // No changes, just stop the loader
    dispatch({
      type: "ADD_MENU_LOADER",
      payload: false,
    });
  }
};


export const deleteMenu = (id) => async (dispatch) => {
  firebase
    .firestore()
    .collection("menus")
    .doc(id)
    .update({
      isDeleted: true,
    })
    .then(() => {
      toast.success("Menu Deleted");
      dispatch({
        type: "DELETED_MENU",
        payload: id,
      });
    });
};

export const addMenu = (payload, onSuccess) => async (dispatch) => {
  dispatch({
    type: "ADD_MENU_LOADER",
    payload: true,
  });
  try {
    if (!payload.images) {
      alert("Please Add MENU Images");
      dispatch({
        type: "ADD_MENU_LOADER",
        payload: false,
      });
      return;
    }

    const uploadImagesPromises = payload.images
      ? payload.images?.map(async (image) => {
          const fileName = image.name;
          const fileName1 = fileName.slice(fileName.lastIndexOf("."));
          const fileName2 = uuidv4() + fileName1.toLowerCase();
          const storageRef = firebase.storage().ref(fileName2);
          await storageRef.put(image);
          const url = await storageRef.getDownloadURL();
          return url;
        })
      : [];

    const uploadImages = await Promise.all(uploadImagesPromises);

    const newMenu = {
      ...payload,
      views: 0,
      images: uploadImages,
      isDeleted: false,
    };

    const docRef = await firebase.firestore().collection("menus").add(newMenu);

    // Fetch the document data using the document reference
    const menuDoc = await firebase
      .firestore()
      .collection("menus")
      .doc(docRef.id)
      .get();

    dispatch({
      type: "ADDED_MENU",
      payload: {
        id: docRef.id,
        ...menuDoc.data(),
      },
    });

    onSuccess({
      type: "item",
      id: docRef.id,
      ...menuDoc.data(),
    });
    toast.success("Item Added Successfully");
    dispatch({
      type: "ADD_MENU_LOADER",
      payload: false,
    });
  } catch (error) {
    console.log("Error adding menu: ", error.message);
    toast.warn(error.message);
    dispatch({
      type: "ADD_MENU_LOADER",
      payload: false,
    });
  }
};

export const updateParentMenu = (parentMenu) => async (dispatch) => {
  try {
    const updateBatch = firebase.firestore().batch();
    parentMenu.forEach((item) => {
      const docRef = firebase
        .firestore()
        .collection("parentMenus")
        .doc(item.id);
      updateBatch.update(docRef, {
        ...item,
      });
    });
    await updateBatch.commit();
  } catch (error) {
    toast.error(error.message);
  } finally {
  }
};

export const getCategories =
  (id, search, hitsPerPage, currentPage) => async (dispatch) => {
    const index = client.initIndex("categories");
    index
      .search(search, {
        filters: `restaurantID:${id}`,
        hitsPerPage: hitsPerPage,
        page: currentPage,
      })
      .then((response) => {
        let { hits, ...rest } = response;
        dispatch({
          type: "CATEGORIES",
          payload: hits,
        });

        dispatch({ type: "CATEGORIES_DETAILS", payload: rest });
      });
    // firebase
    // 	.firestore()
    // 	.collection('categories')
    // 	.where('restaurantID', '==', id)
    // 	.onSnapshot(data => {
    // 		let categories = [];
    // 		for (var doc of data.docs) {
    // 			categories.push({ id: doc.id, ...doc.data() });
    // 		}
    // 		dispatch({
    // 			type: 'CATEGORIES',
    // 			payload: categories,
    // 		});
    // 	});
  };

export const addCategory =
  (payload, onSuccess = () => {}, categoryID) =>
  async (dispatch) => {
    dispatch({
      type: "CATEGORY_LOADER",
      payload: true,
    });
    const imageFile = payload?.imageURL;

    if (imageFile) {
      const fileName = imageFile.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref("categories_images/" + fileNameWithExtension);

      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

      payload.imageURL = downloadURL;
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("categories")
        .add({
          ...payload,
        })
        .then((doc) => {
          const categoryId = doc.id;
          dispatch({
            type: "CATEGORY_LOADER",
            payload: false,
          });
          dispatch({
            type: "ADDED_CATEGORY",
            payload: {
              id: doc.id,
              ...payload,
            },
          });
          onSuccess(categoryId);
          toast.success("Category added Successfully");
          resolve(categoryId); // Resolve the promise with categoryId
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
    });
  };

export const editCategory =
  (id, name, image, imageToRemove, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "CATEGORY_LOADER",
      payload: true,
    });

    try {
      let imageURL = null;

      if (imageToRemove && !image) {
        // Only remove the image if there is an image to remove and no new image is being added
        firebase.storage().refFromURL(imageToRemove).delete();
      } else if (image instanceof Blob) {
        let fileName = image?.name;
        let fileName1 = fileName.slice(fileName.lastIndexOf("."));
        let fileName2 = uuidv4() + fileName1.toLowerCase();
        let storageRef = await firebase
          .storage()
          .ref("categories_images/" + fileName2)
          .put(image);
        imageURL = await storageRef.ref.getDownloadURL();
      } else {
        imageURL = image;
      }

      await firebase.firestore().collection("categories").doc(id).update({
        categoryName: name,
        imageURL: imageURL,
      });

      onSuccess({
        id: id,
        name,
        imageURL,
      });
      dispatch({
        type: "UPDATED_CATEGORY",
        payload: {
          id: id,
          categoryName: name,
          imageURL: imageURL,
        },
      });
      toast.success("Category updated Successfully");
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Error updating category");
    }

    dispatch({
      type: "CATEGORY_LOADER",
      payload: false,
    });
  };

export const deleteCategoryNew =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("menus")
      .where("categoriesID", "==", payload)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            isDeleted: true,
          });
        });
      })
      .catch((error) => {
        console.error("Error updating documents in 'menus' collection:", error);
      });
    firebase
      .firestore()
      .collection("categories")
      .doc(payload)
      .update({
        isDeleted: true,
      })
      .then(onSuccess())
      .catch((error) => {
        console.error(
          "Error updating document in 'categories' collection:",
          error
        );
      });
  };
export const deleteItem =
  (ID, onSuccess = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("menus")
      .doc(ID)
      .update({
        isDeleted: true,
      })
      .then(onSuccess());
  };

export const deleteCategory = (id, imageToRemove) => async (dispatch) => {
  dispatch({
    type: "CATEGORY_EDIT_LOADER",
    payload: true,
  });

  try {
    if (imageToRemove) {
      firebase.storage().refFromURL(imageToRemove).delete();
    }

    await firebase.firestore().collection("categories").doc(id).delete();
    dispatch({
      type: "DELETED_CATEGORY",
      payload: id,
    });
    toast.success("Category deleted successfully");
  } catch (error) {
    console.error("Error deleting category:", error);
    toast.error("Error deleting category");
  }

  dispatch({
    type: "CATEGORY_EDIT_LOADER",
    payload: false,
  });
};

export const getLabels =
  (id, search, hitsPerPage, currentPage) => async (dispatch) => {
    const index = client.initIndex("labels");
    const resId = null;
    index
      .search(search, {
        filters: `(restaurantID:${id} OR restaurantID:${resId}) AND isDeleted:false`,
        hitsPerPage: hitsPerPage,
        page: currentPage,
        // sort: ['createdAt:desc']
      })
      .then((response) => {
        let { hits, ...rest } = response;

        dispatch({
          type: "LABELS",
          payload: hits,
        });

        dispatch({ type: "LABELS_DETAILS", payload: rest });
      });
  };

export const addlabel =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "ADD_LABEL_LOADER",
      payload: true,
    });

    const imageFile = payload?.labelURL;

    if (imageFile) {
      const fileName = imageFile.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref("label_images/" + fileNameWithExtension);
      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

      payload.labelURL = downloadURL;
    }

    await firebase
      .firestore()
      .collection("labels")
      .add({
        ...payload,
      })
      .then((doc) => {
        onSuccess();
        toast.success("Label added Successfully");
        dispatch({
          type: "ADDED_LABEL",
          payload: {
            id: doc.id,
            ...payload,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: "ADD_LABEL_LOADER",
          payload: false,
        });
      });
  };

export const editlabel =
  (id, payload, labelImgRemove, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "EDIT_LABEL_LOADER",
      payload: true,
    });
    if (labelImgRemove) {
      const imagePath = decodeURIComponent(
        labelImgRemove.split("?alt=media")[0]
      );
      firebase
        .storage()
        .refFromURL(imagePath)
        .delete()
        .catch(function (error) {
          console.error("Error deleting previous image:", error);
        });
    }

    const imageFile = payload?.labelURL;

    if (imageFile?.name) {
      const fileName = imageFile.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf("."));
      const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
      const storageRef = firebase
        .storage()
        .ref("label_images/" + fileNameWithExtension);
      const uploadTaskSnapshot = await storageRef.put(imageFile);
      const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

      payload.labelURL = downloadURL;
    }

    try {
      const labelRef = firebase.firestore().collection("labels").doc(id);
      await labelRef.update({
        ...payload,
      });
      onSuccess();
      toast.success("Label updated Successfully");
      dispatch({
        type: "UPDATE_LABEL",
        payload: {
          id: labelRef.id, // Use labelRef.id to get the ID of the updated document
          ...payload,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error("Error updating label");
    } finally {
      dispatch({
        type: "EDIT_LABEL_LOADER",
        payload: false,
      });
    }
  };

export const deleteLabel =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(labelLoader(true));
    await firebase
      .firestore()
      .collection("labels")
      .doc(id)
      .update({
        isDeleted: true,
      })
      .then(() => {
        dispatch({
          type: "DELETED_LABEL",
          payload: id,
        });
        toast.success("Label Deleted Successfully");
        dispatch(labelLoader(false));
        onSuccess();
      });
  };
export const addParentMenu =
  (name, restaurantID, onSuccess) => async (dispatch) => {
    dispatch({
      type: "ADD_MENU_LOADER",
      payload: true,
    });
    try {
      const docRef = await firebase.firestore().collection("parentMenus").doc();
      await docRef.set({
        name,
        restaurantID,
        position: 0,
        createdAt: firebase.firestore.Timestamp.now(),
        isDeleted: false,
        isVisible: true,
      });
      toast.success("Menu Added Successfully");
      onSuccess(docRef.id);
      dispatch({
        type: "ADD_MENU_LOADER",
        payload: false,
      });
    } catch (error) {
      toast.error(error.message);
      dispatch({
        type: "ADD_MENU_LOADER",
        payload: false,
      });
    }
  };

export const getAllParentMenu = (restaurantID) => async (dispatch) => {
  dispatch({
    type: "ADD_MENU_LOADER",
    payload: true,
  });
  try {
    const parentMenus = [];
    firebase
      .firestore()
      .collection("parentMenus")
      .where("restaurantID", "==", restaurantID)
      .where("isDeleted", "==", false)
      .orderBy("position", "asc")
      .onSnapshot((querySnapshot) => {
        parentMenus.length = 0; // Clear the array before populating it again
        querySnapshot.forEach((doc) => {
          parentMenus.push({ id: doc.id, ...doc.data() });
        });
        dispatch({
          type: "ALL_PARENT_MENU",
          payload: parentMenus,
        });
        dispatch({
          type: "ADD_MENU_LOADER",
          payload: false,
        });
      });
  } catch (error) {
    console.log("error.message", error);
    alert(error.message);
    dispatch({
      type: "ADD_MENU_LOADER",
      payload: false,
    });
  }
};

export const menuActivation = (collection, id, active) => async (dispatch) => {
  firebase
    .firestore()
    .collection(collection)
    .doc(id)
    .update({
      isVisible: active,
    })
    .catch((error) => {
      console.error(
        "Error updating document in 'categories' collection:",
        error
      );
    });
};

export const updateParent =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    firebase
      .firestore()
      .collection("parentMenus")
      .doc(id)
      .update({
        name: payload,
      })
      .then(onSuccess);
  };

export const getParentMenu = (parentMenuID, onSuccess) => async (dispatch) => {
  try {
    const menu = [];

    const querySnapshot = await firebase
      .firestore()
      .collection("categories")
      .where("menuID", "==", parentMenuID)
      .where("isDeleted", "==", false)
      .get();

    querySnapshot.forEach((doc) => {
      menu.push({
        type: "category",
        openSubMenu: true,
        addItems: false,
        id: doc.id,
        ...doc.data(),
        items: [],
      });
    });

    const itemsSnapshot = await firebase
      .firestore()
      .collection("menus")
      .where("menuID", "==", parentMenuID)
      .get();

    itemsSnapshot.forEach((doc) => {
      const item = {
        type: "item",
        id: doc.id,
        ...doc.data(),
      };

      const categoryID = item.categoriesID;

      const matchingCategory = menu.find(
        (category) => category.id === categoryID
      );

      if (matchingCategory) {
        matchingCategory.items.push(item);
      } else {
        menu.push(item);
      }
    });

    // console.log(menu);

    dispatch({
      type: "EDIT_MENU",
      payload: menu,
    });
    onSuccess(menu);
  } catch (error) {
    alert(error.message);
  }
};

export const getParentMenuData = (ID, onSuccess) => async (dispatch) => {
  await firebase
    .firestore()
    .collection("parentMenus")
    .doc(ID)
    .get()
    .then((doc) => {
      if (doc.exists) {
        onSuccess({ id: doc.id, ...doc.data() });
      }
    });
};

export const deleteMenuNew =
  (parentMenuID, categoriesID) => async (dispatch) => {
    dispatch({
      type: "DELETE_MENU_LOADER",
      payload: true,
    });
    try {
      await firebase
        .firestore()
        .collection("parentMenus")
        .doc(parentMenuID)
        .update({
          isDeleted: true,
        });
      const updateCategoryPromises = categoriesID.map((category) => {
        return firebase
          .firestore()
          .collection("categories")
          .doc(category)
          .update({
            isDeleted: true,
          });
      });
      await Promise.all(updateCategoryPromises);
      await firebase
        .firestore()
        .collection("menus")
        .where("menuID", "==", parentMenuID)
        .get()
        .then((querySnapshot) => {
          const updateMenuPromises = querySnapshot.docs.map((doc) => {
            return doc.ref.update({
              isDeleted: true,
            });
          });
          return Promise.all(updateMenuPromises);
        });

      dispatch({
        type: "DELETE_MENU_LOADER",
        payload: false,
      });
      toast.success("Menu Deleted Successfully");
    } catch (error) {
      dispatch({
        type: "DELETE_MENU_LOADER",
        payload: false,
      });
      console.error("Error deleting menu:", error);
    }
  };

export const duplicateCategory =
  (payload, menu, onSuccess = () => {}) =>
  async (dispatch) => {
    if (payload?.imageURL instanceof File) {
      const imageFile = payload.imageURL;

      if (imageFile) {
        const fileName = imageFile.name;
        const fileExtension = fileName.slice(fileName.lastIndexOf("."));
        const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
        const storageRef = firebase
          .storage()
          .ref("categories_images/" + fileNameWithExtension);

        const uploadTaskSnapshot = await storageRef.put(imageFile);
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

        payload.imageURL = downloadURL;
      }
    }
    console.log({ payload, menu });
    // Continue with Firestore operation
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("categories")
        .add({
          ...payload,
        })
        .then((doc) => {
          const categoryId = doc.id;
          if (menu?.length > 0) {
            menu?.forEach(async (item) => {
              console.log({ item });

              const menuDoc = await firebase
                .firestore()
                .collection("menus")
                .doc(item.id)
                .get();
              const menuData = menuDoc.data();

              if (menuData) {
                const newItem = { ...menuData };
                newItem.categoriesID = categoryId; // Update the categoriesID

                // You can add your image update logic here if needed

                delete newItem.id; // Remove the original ID

                await firebase.firestore().collection("menus").add(newItem);

                console.log("Duplicated and updated item:", newItem);
              } else {
                console.log("Item data not found for ID:", item.id);
              }
            });
          }

          dispatch({
            type: "ADDED_CATEGORY",
            payload: {
              id: doc.id,
              ...payload,
            },
          });
          onSuccess(categoryId);
          toast.success("Category Duplicated");
          resolve(categoryId);
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
    });
  };

export const duplicateItem =
  (ID, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log({ ID });

    try {
      const docSnapshot = await firebase
        .firestore()
        .collection("menus")
        .doc(ID)
        .get();
      const data = docSnapshot.data();

      const newDocRef = await firebase
        .firestore()
        .collection("menus")
        .add({
          ...data,
        });

      console.log("New document ID:", newDocRef.id);

      onSuccess(newDocRef?.id);
      toast.success("Items Duplicated");
    } catch (error) {
      console.error("Error duplicating item:", error);
      // Handle error if needed
    }
  };

export const labelLoader = (val) => async (dispatch) => {
  dispatch({
    type: "LABELS_LOADER",
    payload: val,
  });
};
