import belldark from "./assets/img/icons/sidebarIcons/belldark.png";
import catalog from "./assets/img/icons/sidebarIcons/catalog.png";
import customers from "./assets/img/icons/sidebarIcons/customers.png";
import customize from "./assets/img/icons/sidebarIcons/customize.png";
import dashboard from "./assets/img/icons/sidebarIcons/dashboard.png";
import menuManage from "./assets/img/icons/sidebarIcons/menuManage.png";
import order from "./assets/img/icons/sidebarIcons/order.png";
import report from "./assets/img/icons/sidebarIcons/report.png";
import request from "./assets/img/icons/sidebarIcons/request.png";
import settings from "./assets/img/icons/sidebarIcons/settings.png";
import staff from "./assets/img/icons/sidebarIcons/staff.png";
import LandingLayout from "layouts/LandingLayout";
import AccountSettings from "views/AccountSettings";
import AllNotifcations from "views/AllNotifcations";
import Billing from "views/BillingSettings";
import Category from "views/Category";
import Catalog from "views/Catolog";
import CheckoutQuestion from "views/CheckoutQuestion";
import ClientSettingMenu from "views/ClientSettingMenu";
import ClientSettings from "views/ClientSettings";
import CreateNewMenu from "views/CreateNewMenu";
import Customers from "views/Customers";
import Customization from "views/Customization";
import Holidays from "views/Holidays";
import Index from "views/Index";
import KitchenStaff from "views/KitchenStaff";
import Orders from "views/Orders";
import OrdersResturant from "views/OrdersResturant";
import PaymentConnection from "views/PaymentConnection";
import PaymentsAndReports from "views/PaymentsAndReports";
import PickupOrDelivery from "views/PickupOrDelivery";
import PlatformFeedback from "views/PlatformFeedback";
import Requests from "views/Requests";
import RestaurantCurrency from "views/RestaurantCurrency";
import RestaurantLanguage from "views/RestaurantLanguage";
import Restaurants from "views/Restaurants";
import Settings from "views/Settings";
import StoreTimings from "views/StoreTimings";
import SubAdmin from "views/SubAdmin";
import { Subscription } from "views/Subscription";
import Tutorials from "views/Tutorials";
import VenueSettingMenu from "views/VenueSettingMenu";
import ForgetPassword from "views/auth/ForgetPassword";
import Login from "views/auth/Login";
import PaymentResponse from "views/auth/PaymentResponse";
import SignUp from "views/auth/SignUp";
import bellactive from "./assets/img/icons/activeIcons/bellactive.png";
import catalogactive from "./assets/img/icons/activeIcons/catalogactive.png";
import customersactive from "./assets/img/icons/activeIcons/customersactive.png";
import customizeactive from "./assets/img/icons/activeIcons/customizeactive.png";
import dashboardactive from "./assets/img/icons/activeIcons/dashboardactive.png";
import menuManageActive from "./assets/img/icons/activeIcons/menuManageActive.png";
import orderactive from "./assets/img/icons/activeIcons/orderactive.png";
import reportactive from "./assets/img/icons/activeIcons/reportactive.png";
import requestactive from "./assets/img/icons/activeIcons/requestactive.png";
import settingsactive from "./assets/img/icons/activeIcons/settingsactive.png";
import staffactive from "./assets/img/icons/activeIcons/staffactive.png";
import LandingPage from "views/Landing/LandingPage";
import AboutUs from "views/Landing/AboutUs";
import Faqs from "views/Landing/Faqs";
import LandingTutorials from "views/Landing/LandingTutorials";
import ContactUs from "views/Landing/ContactUs";
import Drivers from "views/Drivers";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: dashboard,
    activeIcon: dashboardactive,
    component: Index,
    type: "admin",
    layout: "/admin",
  },
  {
    path: "/merchants",
    name: "Merchants",
    icon: order,
    activeIcon: orderactive,
    component: Restaurants,
    type: "admin",
    layout: "/admin",
  },
  {
    path: "/platform_feedback",
    name: "Platform Feedbacks",
    icon: request,
    activeIcon: requestactive,
    component: PlatformFeedback,
    type: "admin",
    layout: "/admin",
  },
  {
    path: "/sub-admin",
    name: "Sub-Admin",
    icon: staff,
    activeIcon: staffactive,
    component: SubAdmin,
    type: "admin",
    layout: "/admin",
  },
  {
    path: "/payments-reports",
    name: "Payments & Reports",
    icon: report,
    activeIcon: reportactive,
    component: PaymentsAndReports,
    type: "admin",
    layout: "/admin",
  },

  {
    path: "/new-menu",
    name: "Menu",
    icon: menuManage,
    activeIcon: menuManageActive,
    component: CreateNewMenu,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/payment-connection",
    name: "Payment Connection",
    icon: "fa fa-list text-primary",
    component: PaymentConnection,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/client-setting-menu",
    name: "Setting",
    icon: "fa fa-list text-primary",
    component: ClientSettingMenu,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/venue-setting-menu",
    name: "Setting",
    component: VenueSettingMenu,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/venue-restaurant-currency",
    name: "Setting",
    component: RestaurantCurrency,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/venue-restaurant-language",
    name: "Setting",
    component: RestaurantLanguage,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/store-timings",
    name: "Store Timings",
    icon: "fa fa-list text-primary",
    component: StoreTimings,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/pickup-or-delivery",
    name: "Pickup or Delivery",
    icon: "fa fa-list text-primary",
    component: PickupOrDelivery,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/client-settings",
    name: "Client Settings",
    icon: "fa fa-list text-primary",
    component: ClientSettings,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/accout-settings",
    name: "Account Settings",
    icon: "fa fa-list text-primary",
    component: AccountSettings,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "fa fa-list text-primary",
    component: Billing,
    type: "restaurant",
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/kitchen_staff",
    name: "Staff",
    icon: staff,
    activeIcon: staffactive,
    component: KitchenStaff,
    type: "restaurant",

    layout: "/admin",
  },
  {
    path: "/drivers",
    name: "Drivers",
    icon: staff,
    activeIcon: staffactive,
    component: Drivers,
    type: "restaurant",

    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: customers,
    activeIcon: customersactive,
    component: Customers,
    type: "restaurant",

    layout: "/admin",
  },
  {
    path: "/category",
    name: "Categories",
    icon: "fas fa-address-card text-primary",
    component: Category,
    type: "restaurant",

    layout: "/admin",
  },
  {
    path: "/order",
    name: "Orders",
    icon: order,
    activeIcon: orderactive,
    component: OrdersResturant,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: order,
    activeIcon: orderactive,
    component: Orders,
    type: "admin",
    layout: "/admin",
  },

  {
    path: "/requests",
    name: "Requests",
    icon: request,
    activeIcon: requestactive,
    component: Requests,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/catalog",
    name: "Catalog",
    icon: catalog,
    activeIcon: catalogactive,
    component: Catalog,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/checkoutQuestions",
    name: "Customer Feedback",
    icon: report,
    activeIcon: reportactive,
    component: CheckoutQuestion,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    icon: catalog,
    activeIcon: catalogactive,
    component: Tutorials,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: settings,
    activeIcon: settingsactive,
    component: Settings,
    type: "restaurant",
    layout: "/admin",
  },
  {
    name: "Customization",
    path: "/customization",
    activeIcon: customizeactive,
    icon: customize,
    component: Customization,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifcations",
    icon: belldark,
    activeIcon: bellactive,
    component: AllNotifcations,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/holidays",
    name: "Holidays",
    icon: "fas fa-cog text-danger",
    component: Holidays,
    type: "restaurant",
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },

  {
    path: "/home",
    name: "Landing",
    icon: "ni ni-key-25 text-info",
    component: LandingPage,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/about",
    name: "About us",
    icon: "ni ni-key-25 text-info",
    component: AboutUs,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/contact",
    name: "Contact us",
    icon: "ni ni-key-25 text-info",
    component: ContactUs,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    icon: "ni ni-key-25 text-info",
    component: LandingTutorials,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/faqs",
    name: "FAQS",
    icon: "ni ni-key-25 text-info",
    component: Faqs,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    icon: "ni ni-key-25 text-info",
    component: SignUp,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/payment-response",
    name: "Payment Response",
    icon: "ni ni-key-25 text-info",
    component: PaymentResponse,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgetPassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: dashboard,
    activeIcon: dashboardactive,
    component: Subscription,
    type: "restaurant",
    layout: "/admin",
  },
];
export default routes;
