import firebase from "../../config/firebase";
import algoliasearch from "algoliasearch";
import { toast } from "react-toastify";
import { createNullCache } from "@algolia/cache-common";
import { RepositoryFactory } from "repository/RepositoryFactory";
const client = algoliasearch("GPYJEAVUBP", "b1f999bc5b264defe930762391d240c6", {
  responsesCache: createNullCache(),
});
let User = RepositoryFactory.get("user");
export const getAllDrivers =
  (ID, search, hitsPerPage, currentPage) => async (dispatch) => {
    dispatch(driverLoader(true));
    const index = client.initIndex("users");
    index
      .search(search, {
        filters: `isDeleted:false AND type:driver AND restaurantID:${ID}`,
        hitsPerPage: hitsPerPage,
        page: currentPage,
      })
      .then((response) => {
        let { hits, ...rest } = response;

        dispatch({
          type: "GET_ALL_DRIVERS",
          payload: hits,
        });
        dispatch({ type: "DRIVER_DETAILS", payload: rest });
        dispatch(driverLoader(false));
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  };

export const updateDriver = (id, obj, onSuccess) => async (dispatch) => {
  dispatch(driverLoader(true));
  await User.updateDriver(id, obj)
    .then(async (res) => {
      console.log(res, "driver updated response");
      dispatch(driverLoader(false));
      dispatch({
        type: "DRIVER_UPDATED",
        payload: {
          id,
          obj,
          // permissions,
        },
      });
      onSuccess();
    })
    .catch((err) => {
      toast.error(
        err?.response?.data?.error
          ? err?.response?.data?.error
          : err?.response?.data?.message
      );
      dispatch(driverLoader(false));
    });
};

export const deleteDriver =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(driverLoader(true));
    await firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({
        isDeleted: true,
      })
      .then(async () => {
        dispatch(driverLoader(false));
        toast.success("Driver has been deleted Successfully...");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(driverLoader(false));
      });
  };

const driverLoader = (data) => async (dispatch) => {
  dispatch({
    type: "DRIVER_LOADER",
    payload: data,
  });
};
export const getRestaurantDrivers = (restaurantID) => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("users")
      .where("type", "==", "driver")
      .where("restaurantID", "==", restaurantID)
      .where("isAssigned", "==", false)
      .where("isDeleted", "==", false)
      .onSnapshot(async (query) => {
        let temp = [];
        if (!query.empty) {
          for (let doc of query.docs) {
            temp.push({ id: doc.id, ...doc.data() });
          }
        }
        console.log(temp,"drivers");
        dispatch({ type: "GET_RESTAURANT_DRIVERS", payload: temp });
      });
  } catch (err) {
    alert(err.message);
  }
};
