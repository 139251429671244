import Stripe from "components/Stripe";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { upgradeSubscription } from "store/actions/subscriptionAction";
import { unsubscribe } from "store/actions/subscriptionAction";
import subsciptionAPI from "../../repository/UserRepository";
import { AppyCoupon } from "store/actions/subscriptionAction";

const SubscriptionTable = () => {
  const { user, uid } = useSelector((state) => state.auth);
  const { couponData } = useSelector((state) => state.customer);
  const { loading } = useSelector((state) => state.subscription);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showStripe, setShowStripe] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponid, setCouponId] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [validCoupon, setvalidCoupon] = useState(false);
  const [amount, setAmount] = useState(0);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const toggle = () => setModal(!modal);
  const updateToggle = () => setUpdateModal(!updateModal);
  const Apply = async () => {
    let updatedAmount = amount;
    if (coupon) {
      if (subscriptionType === "monthly") {
        updatedAmount = 29.99;
      }
      if (subscriptionType === "yearly") {
        updatedAmount = 300;
      }
      const obj = {
        coupon,
        amount: updatedAmount,
      };
      await dispatch(AppyCoupon(obj));
      setAmount(updatedAmount);
    }
  };

  useEffect(() => {
    if (couponData?.success) {
      setvalidCoupon(couponData?.data?.valid || false);
      setDiscountedAmount(couponData?.data?.discountedAmount || 0);
      setCouponId(couponData?.data?.coupon?.id || "");
    }
  }, [couponData]);
  return (
    <>
      {location.pathname == "/admin/subscriptions" ? (
        <>
          <Card className="shadow px-4 py-3 mb-3">
            <h3 className="pt-2">Subscription</h3>
            {user ? (
              <>
                {user?.subScriptionStatus == "unsubscribe" ? (
                  <Button
                    className="subscriptionBtn btn btn-success text-dark mt-3"
                    disabled={showStripe}
                    onClick={() => {
                      setShowStripe(true);
                    }}
                  >
                    Re-Subscribe
                  </Button>
                ) : (
                  <>
                    <h1 className="mt-3">
                      You are currently subscribed to{" "}
                      {user?.subscription?.subscriptionPlan === "monthly"
                        ? "Monthly Plan ($29.99)"
                        : "Yearly Plan ($300)"}
                    </h1>
                    <Row className="mx-3 mb-3">
                      <Button
                        className="subscriptionBtn btn btn-danger text-white  mt-3"
                        onClick={toggle}
                      >
                        Unsubscribe
                      </Button>
                      <Button
                        className="subscriptionBtn btn text-white  mt-3"
                        color={
                          user?.subscription?.subscriptionPlan === "monthly"
                            ? "success"
                            : "warning"
                        }
                        onClick={updateToggle}
                      >
                        {user?.subscription?.subscriptionPlan === "monthly"
                          ? "Upgrade Subscription"
                          : "Downgrade Subscription"}
                      </Button>
                    </Row>
                  </>
                )}

                {showStripe && (
                  <div className="my-3">
                    <Row className="mb-3">
                      <Col md="8">
                        <FormGroup className="mb-0">
                          <Label className="form-label">Coupon Code</Label>
                          <Input
                            className="form-control"
                            placeholder="Enter Coupon Code"
                            type="text"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="mt-2">
                        <Button
                          className=" btn btn-danger text-white mt-4"
                          disabled={
                            coupon === "" || subscriptionType === "" || loading
                          }
                          onClick={(e) => Apply()}
                        >
                          {loading ? <Spinner size="sm" /> : "Apply"}
                        </Button>
                      </Col>
                    </Row>
                    <FormGroup className="mb-0">
                      <Label className="form-label">Subscription Type</Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={subscriptionType}
                        onChange={(e) => setSubscriptionType(e.target.value)}
                      >
                        <option value="">--select--</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </Input>
                    </FormGroup>
                    <div className="mt-3">
                      <Stripe
                        subscriptionType={subscriptionType}
                        setSubscriptionType={setSubscriptionType}
                        setShowStripe={setShowStripe}
                        couponid={couponid}
                        validCoupon={validCoupon}
                        discountedAmount={discountedAmount}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Spinner color="primary"></Spinner>
            )}
          </Card>
        </>
      ) : (
        <>
          <div className="  py-3 mb-3">
            <h3 className="pt-2">Subscription</h3>
            {user ? (
              <>
                {user?.subScriptionStatus == "unsubscribe" ? (
                  <Button
                    className="subscriptionBtn btn btn-success text-dark mt-3"
                    disabled={showStripe}
                    onClick={() => {
                      setShowStripe(true);
                    }}
                  >
                    Re-Subscribe
                  </Button>
                ) : (
                  <>
                    <h1 className="mt-3">
                      You are currently subscribed to{" "}
                      {user?.subscription?.subscriptionPlan === "monthly"
                        ? "Monthly Plan ($29.99)"
                        : "Yearly Plan ($300)"}
                    </h1>
                    <Row className="mx-3 mb-3">
                      <Button
                        className="subscriptionBtn btn btn-danger text-white  mt-3"
                        onClick={toggle}
                      >
                        Unsubscribe
                      </Button>
                      <Button
                        className="subscriptionBtn btn text-white  mt-3"
                        color={
                          user?.subscription?.subscriptionPlan === "monthly"
                            ? "success"
                            : "warning"
                        }
                        onClick={updateToggle}
                      >
                        {user?.subscription?.subscriptionPlan == "monthly"
                          ? "Upgrade Subscription"
                          : "Downgrade Subscription"}
                      </Button>
                    </Row>
                  </>
                )}

                {showStripe && (
                  <div className="my-3">
                    <FormGroup className="mb-0">
                      <Label className="form-label">Subscription Type</Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={subscriptionType}
                        onChange={(e) => setSubscriptionType(e.target.value)}
                      >
                        <option value="">--select--</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </Input>
                    </FormGroup>
                    <div className="mt-3">
                      <Stripe
                        subscriptionType={subscriptionType}
                        setSubscriptionType={setSubscriptionType}
                        setShowStripe={setShowStripe}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Spinner color="primary"></Spinner>
            )}
          </div>
        </>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Unsubscribe</ModalHeader>
        <ModalBody>
          Are you Sure?
          <br />
          Unsubscribing a restaurant may cause deleting all of the restaurant's
          data.
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="sm"
            onClick={() => {
              setLoader(true);
              dispatch(
                unsubscribe(
                  user?.subscription?.subscriptionID,
                  uid,
                  () => {
                    setLoader(false);
                    toggle();
                    history.push("/admin/subscriptions");
                  },
                  () => {
                    setLoader(false);
                  }
                )
              );
            }}
          >
            {loader ? <Spinner size={"sm"}></Spinner> : "Unsubscribe"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal isOpen={updateModal} toggle={updateToggle}>
        <ModalHeader toggle={updateToggle}>Update Subscripton</ModalHeader>
        <ModalBody>
          Are you Sure?
          <br />
          You want to{" "}
          {user?.subscription?.subscriptionPlan == "monthly"
            ? "Upgrade"
            : "Downgrade"}{" "}
          your subscription
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="sm"
            onClick={() => {
              setLoader(true);
              dispatch(
                upgradeSubscription(
                  user?.subscription,
                  uid,
                  setLoader,
                  updateToggle
                )
              );
            }}
          >
            {loader ? <Spinner size={"sm"}></Spinner> : "Update"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SubscriptionTable;
