import React, { useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Spinner,
  ButtonGroup,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
// import { labelData } from "helpers/labelData";
import { useDispatch, useSelector } from "react-redux";
import { addlabel } from "store/actions/MenuManagmentActions";
import { getLabels } from "store/actions/MenuManagmentActions";
import { useEffect } from "react";
import { editlabel } from "store/actions/MenuManagmentActions";
import { deleteLabel } from "store/actions/MenuManagmentActions";
import { toast } from "react-toastify";

const LabelsMenu = () => {
  const dispatch = useDispatch();
  const { user, userPermissions } = useSelector((state) => state.auth);
  const {
    labelsData,
    labelsRestData,
    addLabelLoader,
    editLabelLoader,
    labelLoader,
  } = useSelector((state) => state.menu);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [labelName, setlabelName] = useState("");
  const [labelImgRemove, setLabelImgRemove] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [labelID, setlabelID] = useState("");
  const [labelDescription, setLabelDescription] = useState("");
  const [searchField, setSearchField] = useState("");
  const [hitsPerPage, setHitsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);

  const labelPermissions = userPermissions?.labels;

  // const handleChange = (e) => {
  //   if (e.target.files.length) {
  //     setImage({
  //       preview: URL.createObjectURL(e.target.files[0]),
  //       raw: e.target.files[0],
  //     });
  //   }
  // };

  const handleChange = (e) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "image/svg",
      "image/webp",
    ];

    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileType = file.type;

      if (allowedTypes.includes(fileType)) {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file,
        });
      } else {
        toast.error(
          "Invalid file type. Please use a jpeg, png, gif, jpg, svg, webp file"
        );
      }
    }
  };

  // const fileInput = useRef(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg|\.jfif)$/i;

  //   if (!allowedExtensions.exec(file.name)) {
  //     toast.error(
  //       "Invalid file type. Please upload only JPG, JPEG ,PNG, SVG, or JFIF images."
  //     );
  //     fileInput.current.value = "";
  //     return;
  //   }

  //   setLabelImg(event.target.files[0]);
  // };

  const handleClick = (page) => {
    setCurrentPage(page);
  };
  const addtoggle = () => {
    setAddModal(!addModal);
  };
  const edittoggle = (item) => {
    setEditModal(!editModal);
    setlabelName(item.labelName);
    setImage({ preview: item?.labelURL, raw: "" });
    setLabelDescription(item.labelDescription);
    setlabelID(item.id);
  };

  const deleteToggle = (item) => {
    setlabelName(item?.labelName);
    setlabelID(item?.id);
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    dispatch(
      getLabels(user?.restaurantID, searchField, hitsPerPage, currentPage)
    );
  }, [searchField, hitsPerPage, currentPage]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = labelsRestData?.nbPages;

    if (totalPages >= 1) {
      const visiblePages = 3; // or 4, depending on your preference
      let startPage = Math.max(currentPage - 1, 0);
      let endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

      if (startPage > 0) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink href="">...</PaginationLink>
          </PaginationItem>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink href="" onClick={() => handleClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (endPage < totalPages - 1) {
        if (endPage < totalPages - 2) {
          pageNumbers.push(
            <PaginationItem key="end-ellipsis" disabled>
              <PaginationLink href="">...</PaginationLink>
            </PaginationItem>
          );
        }

        pageNumbers.push(
          <PaginationItem key={totalPages - 1}>
            <PaginationLink href="" onClick={() => handleClick(totalPages - 1)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <OnlyHeader /> */}
      {/* <Container className="mt--7" fluid> */}
      {/* Table */}
      <Row className="mt-2 ">
        <div className="col px-0">
          <Card className="shadow ">
            <CardHeader>
              <Row>
                <Col sm="6" lg="3" className="mt-3">
                  <h3 className="mb-0 ">Labels</h3>
                </Col>
                <Col sm="6" lg="3" className="mt-3">
                  {" "}
                  <Input
                    placeholder="Search here..."
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </Col>
                <Col sm="6" lg="3" className="mt-3">
                  {" "}
                  <Input
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                    value={hitsPerPage}
                    onChange={(e) => {
                      setHitsPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">Show Entries</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </Input>
                </Col>
                <Col sm="6" lg="3" className="mt-3">
                  {user?.type === "kitchen-admin" ? (
                    <>
                      {labelPermissions?.add ? (
                        <Button color="primary" onClick={addtoggle} block>
                          Add
                        </Button>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Button color="primary" onClick={addtoggle} block>
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            {user?.type === "kitchen-admin" ? (
              <>
                {labelPermissions?.get ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Label Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Image</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    {labelsData?.map((item) => {
                      return (
                        <tr>
                          <th scope="row">{item?.labelName}</th>
                          <td>
                            {item?.labelDescription.slice(0, 40)}
                            ...
                          </td>
                          <td className="label-image">
                            <img
                              className="img-fluid"
                              src={item?.labelURL || ""}
                              alt="label"
                            />
                          </td>
                          {item?.restaurantID != "null" && (
                            <>
                              <td>
                                {labelPermissions?.edit ? (
                                  <Button
                                    className="btn-sm"
                                    color="primary"
                                    onClick={() => edittoggle(item)}
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {labelPermissions?.delete ? (
                                  <Button
                                    className="btn-sm"
                                    color="danger"
                                    onClick={() => {
                                      deleteToggle(item);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </Table>
                ) : (
                  <Row className="py-4 justify-content-center align-items-center">
                    You don't have the permission to access the page
                  </Row>
                )}
              </>
            ) : (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Label Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Image</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {labelsData?.map((item) => {
                    return (
                      <tr>
                        <th scope="row">{item?.labelName}</th>
                        <td>
                          {item?.labelDescription.slice(0, 40)}
                          ...
                        </td>
                        <td className="label-image">
                          <img
                            className="img-fluid"
                            src={item?.labelURL ? item.labelURL : ""}
                            alt="Label image"
                          />
                        </td>
                        {item?.restaurantID != "null" && (
                          <>
                            <td>
                              <Button
                                className="btn-sm"
                                color="primary"
                                onClick={() => edittoggle(item)}
                              >
                                Edit
                              </Button>
                              <Button
                                className="btn-sm"
                                color="danger"
                                onClick={() => {
                                  deleteToggle(item);
                                }}
                              >
                                Delete
                              </Button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}

            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink
                      href=""
                      onClick={() => handleClick(currentPage - 1)}
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {/* Page numbers */}
                  {renderPageNumbers()}
                  {/* Next button */}
                  <PaginationItem
                    disabled={currentPage === labelsRestData?.nbPages - 1}
                  >
                    <PaginationLink
                      href=""
                      onClick={() => handleClick(currentPage + 1)}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>

      {/* Modal for add restaurant */}
      <div>
        <Modal isOpen={addModal} toggle={addtoggle}>
          <ModalHeader toggle={addtoggle}>Add Label Details</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  addlabel(
                    {
                      labelName,
                      labelDescription,
                      labelURL: image?.raw,
                      restaurantID: user?.restaurantID,
                      isDeleted: false,
                    },
                    () => {
                      setLabelDescription("");
                      setlabelName("");
                      setImage({ preview: "", raw: "" });
                      setAddModal(false);
                    }
                  )
                );
              }}
            >
              <FormGroup>
                <Label for="name">Label Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Enter name"
                  value={labelName}
                  onChange={(e) => setlabelName(e.target.value)}
                />
              </FormGroup>
              <div>
                <label htmlFor="upload-button">
                  {image.preview ? (
                    <div className="d-flex flex-column justify-content-start align-items-start mb-2">
                      <Label className="mb-2">Uploaded Label</Label>
                      <div className="upload_label">
                        <img
                          src={image.preview}
                          alt="dummy"
                          width="50"
                          height="50"
                        />
                        <i
                          className="fa-solid fa-x"
                          onClick={() =>
                            setImage({
                              preview: "",
                              raw: "",
                            })
                          }
                        ></i>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      {" "}
                      <Label>Upload Label</Label>
                      <span className="fa-stack fa-2x mt-3 mb-2">
                        <i className="fas fa-circle fa-stack-2x" />
                        <i className="fas fa-store fa-stack-1x fa-inverse" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
                <br />
              </div>
              <FormGroup>
                <Label for="location">Description</Label>
                <Input
                  type="textarea"
                  placeholder="Enter Description"
                  required
                  value={labelDescription}
                  onChange={(e) => setLabelDescription(e.target.value)}
                />
              </FormGroup>

              <div className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {addLabelLoader ? <Spinner size="sm" /> : "Save"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>

      {/* Modal for edit restaurant Details */}
      <div>
        <Modal isOpen={editModal} toggle={edittoggle}>
          <ModalHeader toggle={edittoggle}>Edit Label Details</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                let obj = {
                  labelName,
                  labelDescription,
                };
                if (image.raw) {
                  obj.labelURL = image.raw;
                }
                else{
                  obj.labelURL=image?.preview;
                }
                dispatch(
                  editlabel(labelID, obj, labelImgRemove, () => {
                    setLabelDescription("");
                    setlabelName("");
                    setlabelID("");
                    setImage({ preview: "", raw: "" });
                    setLabelImgRemove("");
                    setEditModal(false);
                  })
                );
              }}
            >
              <FormGroup>
                <Label for="name">Label Name</Label>
                <Input
                  type="text"
                  required
                  value={labelName}
                  onChange={(e) => setlabelName(e.target.value)}
                />
              </FormGroup>
              <div>
                <label htmlFor="upload-button">
                  {image.preview ? (
                    <div className="d-flex flex-column justify-content-start align-items-start mb-2">
                      <Label className="mb-2">Uploaded Label</Label>
                      <div className="upload_label">
                        <img
                          src={image?.preview}
                          alt="dummy"
                          width="50"
                          height="50"
                        />
                        <i
                          className="fa-solid fa-x"
                          onClick={() => {
                            setLabelImgRemove(image?.preview);
                            setImage({
                              preview: "",
                              raw: "",
                            });
                          }}
                        ></i>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      {" "}
                      <Label>Upload Label</Label>
                      <span className="fa-stack fa-2x mt-3 mb-2">
                        <i className="fas fa-circle fa-stack-2x" />
                        <i className="fas fa-store fa-stack-1x fa-inverse" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
                <br />
              </div>
              <FormGroup>
                <Label for="location">Description</Label>
                <Input
                  type="textarea"
                  value={labelDescription}
                  required
                  onChange={(e) => setLabelDescription(e.target.value)}
                />
              </FormGroup>

              <div className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  {editLabelLoader ? <Spinner size="sm" /> : "Update"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      {/* </Container> */}
      <div>
        <Modal isOpen={deleteModal} toggle={deleteToggle}>
          <ModalHeader toggle={deleteToggle}>{labelName}</ModalHeader>
          <ModalBody className="d-flex justify-content-center">
            Deleting labels may cause some issues
          </ModalBody>
          <Row className="d-flex justify-content-center align-items-center my-3">
            <Button
              color="danger"
              onClick={() =>
                dispatch(
                  deleteLabel(labelID, () => {
                    deleteToggle();
                  })
                )
              }
            >
              {labelLoader ? <Spinner size="sm" /> : "Confirm"}
            </Button>{" "}
            <Button color="primary" onClick={deleteToggle}>
              Cancel
            </Button>
          </Row>
        </Modal>
      </div>
    </>
  );
};

export default LabelsMenu;
