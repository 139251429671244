import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row } from "reactstrap";
import Wheel from "@uiw/react-color-wheel";
import { useDispatch, useSelector } from "react-redux";
import { updateColors } from "store/actions/authActions";
import { event } from "jquery";

const PopUp = ({
  id,
  adminColor,
  customerColor,
  handleClose,
  color,
  setColor,
}) => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const { restaurantMedia } = useSelector((state) => state.restaurant);
  const { user } = useSelector((state) => state.auth);

  const [currentColor, setCurrentColor] = useState(color);
  const [tempColor, setTempColor] = useState(color);

  // const handleClickOutside = (event) => {

  //   // console.log(popupRef.current);
  //   // if (popupRef.current && !popupRef.current.contains(event.target)) {

  //   handleClose();

  //   // }
  // };

  const handleColorChange = (color) => {
    if (id === "admin") {
      dispatch({
        type: "Restaurant_Media",
        payload: { ...restaurantMedia, adminColor: color.hex },
      });
    }

    setColor(color.hex);
    setCurrentColor(color.hex);
  };

  const handleUpdateColor = (event) => {
    event.preventDefault();
    let obj = {
      customerColor,
      adminColor,
    };

    if (obj) {
      dispatch(updateColors(user?.restaurantID, obj));
    }

    handleClose();
  };
  const handleCancelColor = (event) => {
    event.preventDefault();

    dispatch({
      type: "Restaurant_Media",
      payload: { ...restaurantMedia, adminColor: tempColor },
    });
    setColor(tempColor);
    setCurrentColor(tempColor);
    handleClose();
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <Container>
      <Row className="popup-box">
        <div>
          <Wheel
            color={color}
            ref={popupRef}
            onChange={handleColorChange}
            // style={{ width: "400px", height: "400px" }} // Adjust the width and height as per your requirement
          />

          <div className="mt-3 d-flex justify-content-between align-item-center w-100 ">
            <Button
              size="sm"
              onClick={(event) => {
                handleUpdateColor(event);
              }}
              color="warning"
            >
              Update
            </Button>
            <Button
              size="sm"
              onClick={(event) => {
                handleCancelColor(event);
              }}
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default PopUp;
