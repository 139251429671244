import jsPDF from "jspdf";
import moment from "moment";
import React from "react";
import { Button } from "reactstrap";
import "jspdf-autotable";

const PrintBill = ({ order,restaurantCurrency }) => {
  const thermalPrint = async (item) => {
    const timestamp = item?.createdAt;
    const unixTimestamp = timestamp._seconds + timestamp._nanoseconds / 1e9;
    const formattedDateTime = moment
      .unix(unixTimestamp)
      .format("DD/MM/YYYY HH:mm:ss A");

    var doc = new jsPDF();
    let pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

    let pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(26);
    doc.setFont(undefined, "bold");
    doc.text("NextEats Restaurant", pageWidth / 2, 15, {
      align: "center",
    });
    doc.setFontSize(18);
    doc.setFont(undefined, "normal");
    doc.text("Rawalpindi", pageWidth / 2, 25, {
      align: "center",
    });
    doc.text("N/A", pageWidth / 2, 33, {
      align: "center",
    });

    doc.setFontSize(26);
    doc.setFont(undefined, "bold");
    doc.text("Bill Detail", 10, 50);
    doc.setFontSize(18);
    doc.setFont(undefined, "normal");
    doc.text("Date : ", 10, 60);
    doc.text(formattedDateTime, 30, 60);
    // doc.text("Bill No. : ", 10, 70);
    // doc.text("123456666", 40, 70);
    if (order.id) {
      doc.text("Order ID : ", 10, 70);
      doc.text(order?.id.toString(), 40, 70);
    }

    doc.setFontSize(26);
    doc.setFont(undefined, "bold");
    doc.text("Customer", 130, 50);
    doc.setFontSize(18);
    doc.setFont(undefined, "normal");
    doc.text(item.customerName || "N/A", 130, 60);

    jsPDF.autoTableSetDefaults({
      headStyles: {},
      margin: { left: 8, right: 8, bottom: 10 },
      bodyStyles: {},
      styles: { fontSize: 21 },
      theme: "plain",
    });

    const tableLine1 = order.id ? 78 : 68;
    const tableLine2 = order.id ? 92 : 82;

    doc.setLineWidth(2.0);
    doc.line(10, tableLine1, 200, tableLine1);
    const columns = ["Name", "Size", "Quantity", "Price"];
    doc.line(10, tableLine2, 200, tableLine2);

    const rows = item.order?.map((elem) => [
      elem.name,
      elem.size,
      elem.quantity,
      elem.totalPrice + " " + restaurantCurrency,
    ]);

    doc.autoTable(columns, rows, { startY: order.id ? 80 : 70 });
    let finalY = doc.lastAutoTable.finalY;
    if (finalY >= 235) {
      doc.addPage();
      finalY = 10;
    }

    let GST = item?.totalAmount - item?.totalPriceItem - item?.tip;
    const percentGST = GST/item?.totalAmount * 100

    doc.text("Total", 90, finalY + 21);
    doc.text("Tip", 90, finalY + 31);
    doc.text("GST", 90, finalY + 41);
    doc.text("Net Total", 90, finalY + 51);
    doc.text(
      `${item.totalPriceItem.toString()} ${restaurantCurrency}`,
      150,
      finalY + 21
    );
    doc.text(`${item.tip.toString()} ${restaurantCurrency}`, 150, finalY + 31);
    doc.text(`${percentGST?.toString()} %`, 150, finalY + 41);
    doc.text(
      `${item.totalAmount.toString()} ${restaurantCurrency}`,
      150,
      finalY + 51
    );
    doc.line(90, finalY + 44, 180, finalY + 44);
    doc.setFontSize(14);

    doc.text("Powered by NextEats", pageWidth / 2, 275, {
      align: "center",
    });
    if (window.innerWidth <= 600) {
      doc.save("order-reciept");
    } else {
      doc.autoPrint();
      window.open(doc.output("bloburl"), "_blank");
    }
  };

  return (
    <div>
      <Button
        size="sm"
        className="mr-3  orders-table__process"
        onClick={() => thermalPrint(order)}
      >
        Print Bill
      </Button>
    </div>
  );
};

export default PrintBill;
